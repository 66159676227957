import React from 'react'

const CurrencyIcon = ({ size }) => {
    return (
        <svg width={size ? size : "19"} height={size ? size : "19"} viewBox="0 0 19 18" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.70248 1.45996C10.0041 1.45996 10.2487 1.70451 10.2487 2.00619V16.2081C10.2487 16.5097 10.0041 16.7543 9.70248 16.7543C9.4008 16.7543 9.15625 16.5097 9.15625 16.2081V2.00619C9.15625 1.70451 9.4008 1.45996 9.70248 1.45996Z" fill="currentColor" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.01428 4.05855C6.6289 3.44392 7.46251 3.09863 8.33172 3.09863H10.7897C11.2201 3.09863 11.6463 3.1834 12.0439 3.34811C12.4416 3.51281 12.8028 3.75422 13.1072 4.05855C13.4115 4.36288 13.6529 4.72417 13.8176 5.1218C13.9823 5.51943 14.0671 5.9456 14.0671 6.37599C14.0671 6.67766 13.8225 6.92221 13.5209 6.92221C13.2192 6.92221 12.9746 6.67766 12.9746 6.37599C12.9746 6.08906 12.9181 5.80495 12.8083 5.53986C12.6985 5.27478 12.5376 5.03391 12.3347 4.83103C12.1318 4.62814 11.8909 4.4672 11.6259 4.3574C11.3608 4.2476 11.0767 4.19108 10.7897 4.19108H8.33172C7.75225 4.19108 7.19651 4.42128 6.78676 4.83103C6.37701 5.24078 6.14681 5.79652 6.14681 6.37599C6.14681 6.95546 6.37701 7.5112 6.78676 7.92095C7.19651 8.3307 7.75225 8.56089 8.33172 8.56089H11.336C12.2052 8.56089 13.0388 8.90618 13.6534 9.52081C14.268 10.1354 14.6133 10.969 14.6133 11.8382C14.6133 12.7075 14.268 13.5411 13.6534 14.1557C13.0388 14.7703 12.2052 15.1156 11.336 15.1156H8.0586C7.1894 15.1156 6.35579 14.7703 5.74116 14.1557C5.12654 13.5411 4.78125 12.7075 4.78125 11.8382C4.78125 11.5366 5.0258 11.292 5.32748 11.292C5.62915 11.292 5.8737 11.5366 5.8737 11.8382C5.8737 12.4177 6.1039 12.9735 6.51364 13.3832C6.92339 13.793 7.47913 14.0231 8.0586 14.0231H11.336C11.9154 14.0231 12.4712 13.793 12.8809 13.3832C13.2907 12.9735 13.5209 12.4177 13.5209 11.8382C13.5209 11.2588 13.2907 10.703 12.8809 10.2933C12.4712 9.88354 11.9154 9.65334 11.336 9.65334H8.33172C7.46251 9.65334 6.6289 9.30805 6.01428 8.69343C5.39965 8.0788 5.05436 7.2452 5.05436 6.37599C5.05436 5.50678 5.39965 4.67317 6.01428 4.05855Z" fill="currentColor" />
        </svg>

    )
}

export default CurrencyIcon