import React from 'react'

const MessageIcon = ({ size }) => {
    return (
        <svg width={size ? size : "20"} height={size ? size - 2 : "18"} viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.1929 12.9967C20.0429 10.401 19.7601 6.84468 17.2332 4.52813C16.0786 3.46972 14.6067 2.81082 13.0167 2.6268C13.0068 2.61443 12.9964 2.60248 12.9855 2.59098C11.6023 1.15415 9.61508 0.330078 7.53341 0.330078C3.57201 0.330078 0.242188 3.28163 0.242188 7.06085C0.242188 8.38587 0.653774 9.65608 1.43563 10.7531L0.343411 14.1823C0.309829 14.2877 0.308164 14.4006 0.338625 14.507C0.369085 14.6133 0.430312 14.7083 0.514602 14.7799C0.598893 14.8515 0.702484 14.8966 0.812345 14.9096C0.922207 14.9225 1.03343 14.9026 1.13203 14.8525L4.45356 13.1635C5.13568 13.4572 5.85901 13.65 6.60807 13.7377C8.05302 15.2572 10.051 16.0352 12.0951 16.0352C13.1576 16.0352 14.2163 15.8187 15.1748 15.4071L18.4964 17.0961C18.5752 17.1361 18.6622 17.157 18.7505 17.157C19.1291 17.157 19.4004 16.7881 19.2851 16.4259L18.1929 12.9967ZM4.67993 12.0349C4.60244 11.9981 4.51753 11.9796 4.43174 11.9807C4.34596 11.9819 4.26158 12.0027 4.18511 12.0416L1.81936 13.2446L2.5919 10.819C2.61961 10.732 2.62567 10.6396 2.60955 10.5498C2.59344 10.4599 2.55563 10.3754 2.49943 10.3034C1.75657 9.35252 1.36391 8.23129 1.36391 7.06085C1.36391 3.96806 4.13153 1.45187 7.53341 1.45187C8.90334 1.45187 10.2222 1.8669 11.2919 2.61428C7.68919 2.97654 4.80385 5.79053 4.80385 9.30444C4.80385 10.3974 5.084 11.4495 5.61218 12.3922C5.29272 12.2972 4.98105 12.1778 4.67993 12.0349ZM15.4434 14.2852C15.3669 14.2463 15.2825 14.2255 15.1967 14.2243C15.1109 14.2232 15.026 14.2417 14.9485 14.2785C14.0741 14.6939 13.0874 14.9134 12.0951 14.9134C8.69319 14.9134 5.92557 12.3972 5.92557 9.30444C5.92557 6.21165 8.69319 3.69546 12.0951 3.69546C15.497 3.69546 18.2646 6.21165 18.2646 9.30444C18.2646 10.4749 17.8719 11.5961 17.129 12.547C17.0728 12.6189 17.035 12.7035 17.0189 12.7933C17.0028 12.8832 17.0089 12.9756 17.0365 13.0626L17.8091 15.4881L15.4434 14.2852Z"
                fill="currentColor" />
            <path d="M9.81871 9.86594C10.1285 9.86594 10.3796 9.61481 10.3796 9.30504C10.3796 8.99526 10.1285 8.74414 9.81871 8.74414C9.50893 8.74414 9.25781 8.99526 9.25781 9.30504C9.25781 9.61481 9.50893 9.86594 9.81871 9.86594Z" fill="currentColor" />
            <path d="M12.0609 9.86594C12.3707 9.86594 12.6218 9.61481 12.6218 9.30504C12.6218 8.99526 12.3707 8.74414 12.0609 8.74414C11.7511 8.74414 11.5 8.99526 11.5 9.30504C11.5 9.61481 11.7511 9.86594 12.0609 9.86594Z" fill="currentColor" />
            <path d="M14.3031 9.86594C14.6129 9.86594 14.864 9.61481 14.864 9.30504C14.864 8.99526 14.6129 8.74414 14.3031 8.74414C13.9933 8.74414 13.7422 8.99526 13.7422 9.30504C13.7422 9.61481 13.9933 9.86594 14.3031 9.86594Z" fill="currentColor" />
        </svg>

    )
}

export default MessageIcon