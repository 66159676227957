import React from 'react'

const Pen = ({ size }) => {
    return (
        <svg width={size ? size : "54"} height={size ? size : "54"} viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_221_564)">
                <path d="M7.08594 46.5042H15.9154L44.6111 17.8085C45.782 16.6376 46.4398 15.0496 46.4398 13.3938C46.4398 11.7379 45.782 10.1499 44.6111 8.97903C43.4403 7.80817 41.8523 7.15039 40.1964 7.15039C38.5406 7.15039 36.9525 7.80817 35.7817 8.97903L7.08594 37.6748V46.5042Z"
                    stroke="currentColor" stroke-width="3.31105" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M32.4766 12.29L41.306 21.1195" stroke="currentColor" stroke-width="3.31105" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M26.9522 17.8083L15.9154 6.77148L7.08594 15.6009L18.1228 26.6378" stroke="currentColor" stroke-width="3.31105" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M15.9204 17.8086L12.6094 21.1196" stroke="currentColor" stroke-width="3.31105" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M35.7826 26.6387L46.8194 37.6755L37.99 46.505L26.9531 35.4681" stroke="currentColor" stroke-width="3.31105" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M35.7876 37.6748L32.4766 40.9859" stroke="currentColor" stroke-width="3.31105" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_221_564">
                    <rect width="52.9768" height="52.9768" fill="white" transform="translate(0.46875 0.149414)" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default Pen