import React from 'react'

const RightArrow = ({ size }) => {
    return (
        <svg width={size ? size : "27"} height={size ? size : "27"} viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.0474 13.5507L5.49219 13.5507" stroke="currentColor" stroke-width="2.20737" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M15.375 6.90192L22.0523 13.5505L15.375 20.2002" stroke="currentColor" stroke-width="2.20737" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    )
}

export default RightArrow