import React, { createContext, useEffect, useState } from 'react'
import { port, slaport } from '../App'
import axios from 'axios'


export const HrStorage = createContext()
const HrStore = ({ children }) => {
    let [active, setactive] = useState("")
    let [allJob, setAllJobs] = useState()
    let [allblog, setallblog] = useState()
    let a = 'hellow'
    let getAllJobs = () => {
        axios.get(`${port}/api/job_description/`).then((response) => {
            setAllJobs(response.data)
        }).catch((error) => {
            console.log(error);
        })
    }
    let getallblog = () => {
        axios.get(`${slaport}/blog/BlogViewSet/`).then((response) => {
            setallblog(response.data)
            console.log(response.data);
        }).catch((error) => {
            console.log(error);
        })
    }
    useEffect(() => {
        getAllJobs()
        getallblog()
    }, [])
    let value = { a, active, allblog,getallblog, setactive, allJob }
    return (
        <HrStorage.Provider value={value} >
            {children}
        </HrStorage.Provider>
    )
}

export default HrStore