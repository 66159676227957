import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { slaport } from '../../App';
import CalenderIcon from '../../SVG/CalenderIcon';

const LatestPost = ({ data }) => {
    let [allBlog, setAllBlog] = useState()
    useEffect(() => {
        axios.get(`${slaport}/blog/BlogViewSet/`).then((response) => {
            setAllBlog(response.data)
            console.log(response.data);
        }).catch((error) => {
            console.log(error);
        })
    }, [])
    function convertDate(dateStr) {
        // Create a new Date object from the date string
        const date = new Date(dateStr);

        // Define an array of month names
        const months = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];

        // Get the day of the month (1-31)
        const day = date.getDate();

        // Get the month name from the months array (0-11)
        const month = months[date.getMonth()];

        // Get the full year (YYYY)
        const year = date.getFullYear();

        // Return the formatted date string
        return `${month} ${day}, ${year}`;
    }
    let navigate = useNavigate()
    return (
        <div className=' bg-white rounded-xl poppins p-3 inter overflow-hidden my-2 '>
            <section className='flex justify-between gap-3 ' >

                <p className='mb-0 rounded-t  fw-semibold '> Recent Blogs </p>
                <button onClick={() => navigate('/blogs')} className='text-blue-600' >
                    Show All
                </button>
            </section>
            <div className='rounded-b '>
                {
                    allBlog && [...allBlog].reverse().slice(0, 3).map((x) => {
                        return (
                            <div onClick={() => navigate(`/blogs/${x.slug}`)}
                                className='flex bg-blue-50 p-3 rounded-xl cursor-pointer items-center my-2 '>
                                <img src={x.img ? x.img : require('../../Assest/building.webp')}
                                    className='object-cover h-[70px] rounded '
                                    width='80px' loading='lazy' alt="Blog Imaeg" />
                                <div className='px-3'>
                                    <p className='text-slate-600 mb-1 flex gap-2 text-xs items-center'>
                                        <span className='text-blue-800'><CalenderIcon size={12} />
                                        </span> {convertDate(x.created_at.slice(0, 10))}  </p>
                                    <p className='text-xs line-clamp-2 text-black opacity-100 mb-0 fw-medium '>
                                        {x.Main_Title} </p>
                                </div>

                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default LatestPost