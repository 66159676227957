import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { domain, hrpohrport, hrport, rt, slaport } from '../App'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import TreandyPost from '../Component/Blog/TreandyPost'
import Allpost from '../Component/Blog/Allpost'
import SubjectSlides from '../Component/Blog/SubjectSlides'
import PopularPost from '../Component/Blog/Popularpost'
import LatestPost from '../Component/Blog/LatestPost'
import { HrStorage } from '../Context/HrStore'
import SearchIcon from '../SVG/SearchIcon'
import HomeNavBar from '../Component/HomeComponent/HomeNavBar'

const AllBlogPage = () => {
    let [allBlog, setAllBlog] = useState()
    let { convertDate,setactive } = useContext(HrStorage)
    let [recentBlog, setRecentBlog] = useState()
    let [popularPost, setPopularPost] = useState()
    useEffect(() => {
        setactive('blog')
        axios.get(`${slaport}/blog/BlogViewSet/`).then((response) => {
            setAllBlog(response.data)
            setRecentBlog(response.data.reverse()[0])
            console.log(response.data);
        }).catch((error) => {
            console.log(error);
        })
    }, [])
    let navigate = useNavigate()
    return (
        <>
            <> <Helmet>
                <title>Our Blog Gives You Many Informations on Particular Topic.    </title>
                <meta name="description" content="Get insightful content on all the courses and more expert tips, latest trends full of Knowledge, Inspiration and software training updates and informations." />
                <link rel="canonical" href={`${domain}/blogs`} />
            </Helmet>
                <h1 className='d-none'>informative blog content </h1>
                <h2 className='d-none'>best blogging tips and tricks </h2>
            </>
            <HomeNavBar />
            <div className='container mx-auto row py-5 col-xl-10'>

                {/* All Contents */}
                <div className='col-lg-8 col-xl-8 h-[125vh]  scrollbar overflow-y-scroll'>
                    {/* Section 1 */}
                    {recentBlog &&
                        <div onClick={() => navigate(`/blogs/${recentBlog.slug}`)}
                            className='relative cursor-pointer inter rounded '>
                            <img src={recentBlog.img ? recentBlog.img : require('../Assest/bgtech.webp')} className='w-full ' loading='lazy' alt="Blog Image" />
                            <section className=' absolute w-full h-full top-0 bg-slate-900 bg-opacity-20 '>
                                <div className='w-4/5 absolute bottom-1 text-xs left-5 rounded px-3 '>
                                    <p className='mb-0 text-sm sm:text-xl fw-bold lg:text-2xl fw-medium'>{recentBlog.Main_Title} </p>
                                    {/* <div className='flex gap-3 items-center'>
                                    <p className='flex gap-2 items-center'><span className='text-blue-600'> <CalenderIcon /> </span> {convertDate(recentBlog.created_at.slice(0, 10))}  </p>
                                    <p className='flex gap-2 items-center'><span className='text-blue-600'> <CommentIcon /> </span> 05 Comments  </p>
                                </div> */}
                                    <p className='d-none d-sm-block sm:text-sm'>
                                        {recentBlog.Paragraph1.slice(0, 100)}
                                        {recentBlog.Paragraph1.length > 100 && "..."}
                                    </p>
                                    <p className=' d-sm-none'>{recentBlog.Paragraph1.slice(0, 50)}
                                        {recentBlog.Paragraph1.length > 50 && "..."} </p>

                                </div>
                            </section>
                        </div>}
                    {/* Subject slides */}
                    <p className='fw-medium text-2xl flex items-center mt-3 gap-2 '> <span className='p-[3px] bg-blue-500 rounded-full h-[12px] w-[1px] '>
                    </span>
                        Digital Marketing </p>
                    <SubjectSlides data={allBlog} name="blog" />
                    <TreandyPost />
                    <Allpost />
                </div>
                {/* Filter sticky */}
                <section className='col-lg-4 col-xl-4 '>
                    {/* <div className=' rounded w-full bg-slate-50 p-3'>
                        <div className='rounded flex w-full border-1 bg-blue-200 bg-opacity-30'>
                            <input type="text" className='w-full outline-none border-0 bg-transparent h-full rounded p-1' placeholder='Search' />
                            <button className='p-[10px] rounded bg-blue-800 text-white'> <SearchIcon /> </button>
                        </div>
                    </div> */}
                    {allBlog && <LatestPost data={allBlog} />}
                    <PopularPost />
                    <div style={{ backgroundColor: 'rgb(247,247,249)' }} className='my-2 p-3 rounded '>
                        <p className='text-black text-lg fw-semibold '>Popular Tag</p>
                        <div className='flex gap-2 flex-wrap'>
                            {
                                ['Education', 'Training', 'Online', 'Learn', 'Course', 'LMS'].map((x) => {
                                    return (
                                        <p className='p-1 text-blue-800 mb-0 px-2 bg-white'>{x} </p>
                                    )
                                })
                            }

                        </div>
                    </div>
                </section>

            </div>
        </>
    )
}

export default AllBlogPage