import React from 'react'
import HomeNavBar from './HomeNavBar'

const HomeBanner = () => {
    return (
        <div>
            <main className='relative poppins ' >
                <img src={require('../../Assest/homeBanner.webp')} alt="Banner" />
                <div className='absolute w-full top-0 ' >
                    <HomeNavBar />
                </div>
                <div className='border-s-2 px-3 absolute top-[25%] d-none d-block -rotate-90 border-slate-400 ' >
                    <p className='mb-1 ' >Phone </p>
                    <a target='_blank' href="tel:+917795986621"
                        className='text-decoration-none text-semibold text-blue-950'>
                        <p className='text-blue-950 mb-0 '>
                            +91 7795986621 </p></a>
                </div>
                <div className='border-s-2 px-3 absolute -left-16 bottom-[28%] d-none d-block -rotate-90 border-slate-400 ' >
                    <p className='mb-1 ' >Email </p>
                    <a target='_blank' href="mailto:hrd@meridatechminds.com"
                        className='text-decoration-none text-semibold text-blue-950'>
                        <p className='text-blue-950 mb-0 '>
                            hrd@meridatechminds.com </p></a>
                </div>
            </main>
        </div>
    )
}

export default HomeBanner