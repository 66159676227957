import React from 'react'

const WorkSamrter = () => {
    return (
        <div className='btngrd xl:min-h-[40vh] poppins' >
            <main className='container row mx-auto py-5' >
                <section className='col-lg-6 ' >
                    <h5 className='text-white text-4xl ' >Get Access to HR Services
                        <br /> in Just 3 Steps</h5>
                    <article className='my-5 ' >

                        <div className='flex gap-4 my-2 ' >
                            <span className='bg-white fw-bold text-blue-700 
                        text-3xl rounded-full
                         w-20 h-20 flex items-center justify-center ' >
                                1
                            </span>
                            <div className='flex-1   ' >
                                <h4 className='text-white ' > Contact Us </h4>
                                <p className='text-slate-200 text-sm line-clamp-2 w-4/5 ' >
                                    We’re here for you whenever you need us, ready to help find the perfect HR solutions for you.
                                </p>
                            </div>
                        </div>

                        <div className='flex gap-4 my-2 ' >
                            <span className='bg-white fw-bold text-blue-700 
                        text-3xl rounded-full
                         w-20 h-20 flex items-center justify-center ' >
                                2
                            </span>
                            <div className='flex-1   ' >
                                <h4 className='text-white ' > Personalize Your Service </h4>
                                <p className='text-slate-200 text-sm line-clamp-2 w-4/5 ' >
                                    We’ll customize our HR solutions to fit your needs, ensuring everything works smoothly.
                                </p>
                            </div>
                        </div>

                        <div className='flex gap-4 my-2 ' >
                            <span className='bg-white fw-bold text-blue-700 
                        text-3xl rounded-full
                         w-20 h-20 flex items-center justify-center ' >
                                3
                            </span>
                            <div className='flex-1   ' >
                                <h4 className='text-white ' > Easy Tracking </h4>
                                <p className='text-slate-200 text-sm line-clamp-2 w-4/5 ' >
                                    Monitor employee performance, payroll, and more with clear, real-time analytics at your fingertips.
                                </p>
                            </div>
                        </div>
                    </article>
                </section>
                <section className='col-lg-6 flex flex-col '  >
                    <button className='rounded-full p-3 px-4 text-blue-800 flex ms-auto bg-white fw-semibold' >
                        Get Started Now
                    </button>
                    {/* Video */}
                    <div className='w-4/5 rounded bg-white h-[300px] my-auto ' >

                    </div>
                </section>
            </main>
            {/* Easy to manage */}


        </div>
    )
}

export default WorkSamrter