import React, { useContext, useEffect, useState } from 'react'
import LatestPost from './Blog/LatestPost'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { hrport, slaport } from '../App'
import { HrStorage } from '../Context/HrStore'
import SearchIcon from '../SVG/SearchIcon'
import ArrowDown from '../SVG/ArrowDown'

const RightSideBlog = () => {
    let { allCourses, allblog } = useContext(HrStorage)
    let [courses, setCourses] = useState()
    let uniqueCategory = new Set([].map((obj) => obj.category))
    let [allCategory, setCategory] = useState([])
    let navigate = useNavigate()
    let [filteredBlog, setFilteredBlog] = useState([])
    let [filteredCourse, setFilteredCourse] = useState([])
    let [searchword, setsearchWord] = useState('')
    let filterAll = (e) => {
        setsearchWord(e.target.value)
        let word = e.target.value
        if (word == '') {
            setFilteredBlog([])
            setFilteredCourse([])
        }
        else {
            let filterBlog = allblog.filter((obj) => obj.Main_Title.toLowerCase().indexOf(word.toLowerCase()) != -1)
            setFilteredBlog(filterBlog)

        }
    }
    let getCategory = () => {
        axios.get(`${slaport}/blog/category_list`).then((response) => {
            console.log("cat", response.data);
            setCategory(response.data)
        }).catch((error) => {
            console.log("cat", error);

        })
    }
    useEffect(() => {
        getCategory()
    }, [])
    return (
        <div>
            <div className='relative rounded w-full p-1 bg-slate-50'>
                <div className='rounded flex w-full items-center  '>
                    <input onChange={filterAll} type="text"
                        className='w-full outline-none border-0 bg-transparent h-full rounded p-1' placeholder='Search' />
                    <button className='p-[10px] rounded text-blue-800'>
                        <SearchIcon />
                    </button>
                </div>
                {searchword && <div className='top-12 left-0 absolute border-1 border-slate-200 shadow-sm z-10  w-full bg-white p-2 rounded '>
                    {
                        filteredBlog.length == 0 && filteredCourse.length == 0 && <div>
                            No course or blog match your request
                        </div>
                    }
                    {filteredBlog && filteredBlog.length > 0 && <section className=''>
                        Blogs
                        <div className='max-h-[24vh] scrollbar3 overflow-y-scroll'>
                            {
                                filteredBlog && filteredBlog.map((obj) => (
                                    <div onClick={() => navigate(`/blogs/${obj.slug}`)} className='border-1 cursor-pointer border-slate-200 p-2 flex gap-2 my-1 rounded '>
                                        <img loading='lazy' src={obj.img} className='w-16 object-cover h-12 rounded ' alt="" />
                                        {obj.Main_Title && obj.Main_Title.slice(0, 30)}{obj.Main_Title && obj.Main_Title.length > 30 && "..."}
                                    </div>
                                ))
                            }
                        </div>
                    </section>}
                    {filteredCourse && filteredCourse.length > 0 && <section className=''>
                        Course
                        <div className='max-h-[24vh] scrollbar3 overflow-y-scroll'>
                            {
                                filteredCourse && filteredCourse.map((obj) => (
                                    <div onClick={() => navigate(`/${obj.slug}`)} className='border-1 cursor-pointer border-slate-200 p-2 flex gap-2 my-1 rounded '>
                                        <img loading='lazy' src={obj.img} className='w-16 object-cover h-12 rounded ' alt="" />
                                        <span className='line-clamp-2 ' > {obj.Title}
                                        </span>
                                    </div>
                                ))
                            }
                        </div>
                    </section>
                    }
                </div>}
            </div>
            {/* Categories */}
            {/* <div style={{ backgroundColor: 'rgb(247,247,249)' }} className='my-2 z-0 p-3 rounded '>
                <p className='text-black text-lg fw-semibold '>Categories</p>
                {
                    [...allCategory].map((x) => (
                        <p onClick={() => navigate(`/category/${x.name}`)} className='flex cursor-pointer text-slate-500 '>
                            <span className='-rotate-90 text-blue-600 '><ArrowDown /> </span>
                            {x.name}
                        </p>
                    ))
                }

            </div> */}
            <LatestPost />
            <div style={{ backgroundColor: 'rgb(247,247,249)' }} className='my-2 p-3 rounded '>
                <p className='text-black text-lg fw-semibold '>Tags</p>
                <div className='flex gap-2 flex-wrap'>

                    {
                        ['Education', 'Training', 'Online', 'Learn', 'Course', 'LMS'].map((x) => {
                            return (
                                <p className='p-1 mb-0 px-2 text-blue-800 bg-white'>{x} </p>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default RightSideBlog