import React, { useContext, useEffect } from 'react'
import HomeNavBar from '../Component/HomeComponent/HomeNavBar'
import { HrStorage } from '../Context/HrStore'
import Footer from '../Component/Footer'
import HomeBanner from '../Component/HomeComponent/HomeBanner'
import OurServices from '../Component/HomeComponent/OurServices'
import WhyChooseUs from '../Component/HomeComponent/WhyChooseUs'
import HomeTestimonial from '../Component/HomeComponent/HomeTestimonial'
import OurBlogs from '../Component/HomeComponent/OurBlogs'
import EventsSection from '../Component/HomeComponent/EventsSection'
import HomeContact from '../Component/HomeComponent/HomeContact'
import { Helmet } from 'react-helmet'

const HomePage = () => {
    let { setactive } = useContext(HrStorage)
    useEffect(() => {
        setactive('home')
    }, [])
    return (
        <div className='homebg' >
            <Helmet>
                <title>Strategic HR Solutions: Partnering for Success</title>
                <meta name="description" content="Partner with our team of experts for strategic HR solutions that are tailored to your needs. Optimise your human resources with our proven consultancy services. Get started today!" />
                <link rel="canonical" href="https://www.meridahr.com/" />
            </Helmet>
            {/* <HomeNavBar /> */}
            <HomeBanner />
            <OurServices />
            <WhyChooseUs />
            <HomeTestimonial />
            <OurBlogs />
            <EventsSection />
            <HomeContact />


        </div>
    )
}

export default HomePage