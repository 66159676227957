import React from 'react'

const LockIcon = ({ size }) => {
    return (
        <svg width={size ? size : "30"} height={size ? size : "30"} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M24.3412 9.25678H21.1515V6.97842C21.1515 5.40735 20.5274 3.90062 19.4164 2.78971C18.3055 1.67879 16.7988 1.05469 15.2277 1.05469C13.6567 1.05469 12.1499 1.67879 11.039 2.78971C9.9281 3.90062 9.304 5.40735 9.304 6.97842V9.25678H6.1143C5.51004 9.25678 4.93053 9.49682 4.50325 9.9241C4.07598 10.3514 3.83594 10.9309 3.83594 11.5351V24.2939C3.83594 24.8982 4.07598 25.4777 4.50325 25.905C4.93053 26.3323 5.51004 26.5723 6.1143 26.5723H24.3412C24.9454 26.5723 25.5249 26.3323 25.9522 25.905C26.3795 25.4777 26.6195 24.8982 26.6195 24.2939V11.5351C26.6195 10.9309 26.3795 10.3514 25.9522 9.9241C25.5249 9.49682 24.9454 9.25678 24.3412 9.25678ZM12.038 6.97842C12.038 6.13246 12.3741 5.32115 12.9723 4.72296C13.5705 4.12478 14.3818 3.78872 15.2277 3.78872C16.0737 3.78872 16.885 4.12478 17.4832 4.72296C18.0814 5.32115 18.4174 6.13246 18.4174 6.97842V9.25678H12.038V6.97842ZM23.8855 23.8383H6.56997V11.9908H23.8855V23.8383ZM17.0504 17.9145C17.0504 18.275 16.9435 18.6274 16.7432 18.9272C16.543 19.2269 16.2583 19.4605 15.9252 19.5985C15.5922 19.7364 15.2257 19.7725 14.8721 19.7022C14.5186 19.6319 14.1938 19.4583 13.9389 19.2034C13.684 18.9485 13.5104 18.6237 13.4401 18.2701C13.3697 17.9166 13.4058 17.5501 13.5438 17.217C13.6817 16.884 13.9154 16.5993 14.2151 16.399C14.5148 16.1988 14.8672 16.0919 15.2277 16.0919C15.7111 16.0919 16.1747 16.2839 16.5166 16.6257C16.8584 16.9675 17.0504 17.4311 17.0504 17.9145Z"
                fill="currentColor" />
        </svg>

    )
}

export default LockIcon