import React from 'react'
import LeafIcon from '../../SVG/LeafIcon'
import ArrowRght from '../../SVG/ArrowRght'
import RightArrow from '../../SVG/RightArrow'
import TargetIcon from '../../SVG/TargetIcon'
import LockIcon from '../../SVG/LockIcon'
import BellIcon from '../../SVG/BellIcon'
import { useNavigate } from 'react-router-dom'

const BoostProductivity = () => {
    let navigate = useNavigate()
    return (
        <div className='poppins text-slate-800 relative my-0 hrbggrd' >
            <img src={require('../../Assest/toruscircle.webp')} alt="Circle" className='absolute bottom-1/4 left-0 w-28 ' />
            <img src={require('../../Assest/diamond.webp')} alt="Diamond" className='absolute top-1/4 right-0 w-24 ' />
            <p className=' text-center fw-semibold text-xl w-fit mx-auto rounded-2xl p-2 px-4 border-2 ' >
                Innovative Tools </p>
            <h5 className='text-center text-3xl sm:text-5xl' > Make Teamwork Easier <br /> and More Productive </h5>
            <p className='text-center w-[380px] sm:w-[440px] mx-auto text-sm ' >
                With our smart tools, your team can stay connected and work together effortlessly, making it easier to get
                things done. </p>
            <img src={require('../../Assest/dashboardImage.webp')} alt="Image"
                className='container my-5 px-0 rounded-xl mx-auto ' />
            <section className='flex flex-wrap container mx-auto py-4 justify-between' >
                {/* Card 1 */}
                <div className='col-6 col-lg-3 p-2 ' >
                    <LeafIcon size={20} />
                    <h5 className=' my-2' >Smooth Hiring Process </h5>
                    <p className=' text-sm' >Manage hiring from application to exit easily. </p>
                    <button onClick={() => navigate(`/service/RPO`)} className='flex gap-2 items-center ' >
                        Learn more <RightArrow size={16} />
                    </button>
                </div>
                {/* Card 2 */}
                <div className='col-6 col-lg-3 p-2 ' >
                    <TargetIcon size={20} />
                    <h5 className=' my-2' >Simple Task Management</h5>
                    <p className=' text-sm' >Organize tasks and track progress with ease. </p>
                    <button onClick={() => navigate(`/service/Contract-Staffing-Solutions`)}
                        className='flex gap-2 items-center ' >
                        Learn more <RightArrow size={16} />
                    </button>
                </div>
                {/* card 3 */}
                <div className='col-6 col-lg-3 p-2 ' >
                    <LockIcon size={20} />
                    <h5 className=' my-2' >Payroll Data at Fingertips </h5>
                    <p className=' text-sm' > Access and manage payroll data instantly, anytime. </p>
                    <button onClick={() => navigate(`/service/Payroll-Processing`)}
                        className='flex gap-2 items-center ' >
                        Learn more <RightArrow size={16} />
                    </button>
                </div>
                {/* Card 4 */}
                <div className='col-6 col-lg-3 p-2 ' >
                    <BellIcon size={20} />
                    <h5 className=' my-2' > Complete Integrated System </h5>
                    <p className=' text-sm' >Handle all HR tasks from one central dashboard.</p>
                    <button onClick={() => navigate(`/service/background-verification`)}
                        className='flex gap-2 items-center ' >
                        Learn more <RightArrow size={16} />
                    </button>
                </div>
            </section>
            <p className='h-[50px]  ' ></p>
        </div>
    )
}

export default BoostProductivity
