import React, { useState } from 'react'

const ServiceWorkProcess = () => {
    let [selectedIndex, setSelectedIndex] = useState(1)
    let [animation, setanimation] = useState('rotateani')
    return (
        <div className='servicebgprocess bg-violet-50 py-10 xl:min-h-[40vh] my-5 flex container-fluid row ' >
            <section className='col-md-6 flex relative ' >
                <img src={require('../../Assest/squareCircle.png')} alt="Circle" className='absolute z-10 left-0 ' />
                <div onMouseEnter={() => setanimation('')}
                    onMouseLeave={() => setanimation('rotateani')}

                    className={`${animation}  rounded-full w-[23rem] xl:right-10 h-[23rem] z-0 relative  ms-auto border-2 border-sky-500 `} >
                    {/* Circle 1 */}
                    <button onClick={() => setSelectedIndex(1)} className={` w-14 h-14 ${selectedIndex == 1 && 'scale-[1.35] border-yellow-300'} duration-500 right-10 border-2 border-slate-50 bg-slate-300 rounded-full absolute  `} >

                    </button>
                    {/* Circle 2 */}
                    <button onClick={() => setSelectedIndex(2)} className={` w-14 h-14 ${selectedIndex == 2 && 'scale-[1.35] border-yellow-300'} duration-500 -right-8 top-36 border-2 border-slate-50 bg-slate-300 rounded-full absolute  `} >

                    </button>
                    {/* Circle 3 */}
                    <button onClick={() => setSelectedIndex(3)} className={` w-14 h-14 ${selectedIndex == 3 && 'scale-[1.35] border-yellow-300'} duration-500 right-12 bottom-0 border-2 border-slate-50 bg-slate-300 rounded-full absolute  `} >

                    </button>
                    {/* Circle 4 */}
                    <button onClick={() => setSelectedIndex(4)} className={` w-14 h-14 ${selectedIndex == 4 && 'scale-[1.35] border-yellow-300'} duration-500 left-12 bottom-0 border-2 border-slate-50 bg-slate-300 rounded-full absolute  `} >

                    </button>
                    {/* Circle 5 */}
                    <button onClick={() => setSelectedIndex(5)} className={` w-14 h-14 ${selectedIndex == 5 && 'scale-[1.35] border-yellow-300'} duration-500 left-12 top-0 border-2 border-slate-50 bg-slate-300 rounded-full absolute  `} >

                    </button>
                    {/* Circle 6 */}
                    <button onClick={() => setSelectedIndex(6)} className={` w-14 h-14 ${selectedIndex == 6 && 'scale-[1.35] border-yellow-300'} duration-500 -left-8 top-36 border-2 border-slate-50 bg-slate-300 rounded-full absolute  `} >

                    </button>
                </div>
            </section>
            {/* right side */}
            <section className='col-md-4 p-4 ' >
                <h5 className='text-lg fw-semibold textgrdservice uppercase ' >Work process </h5>
                <p className=' fw-bold text-3xl sm:text-4xl ' >What Says Our Clients </p>
                <p className='text-slate-400 ' > There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form,
                    by injected </p>
                {/* Testimonial */}
                <article className='bg-white p-3 rounded-xl ' >
                    <div className='flex gap-3' >
                        <img src={require('../../Assest/DashboardLight.webp')} className='w-20 h-20 rounded-full ' alt="Image" />
                        <div className='text-sm ' >
                            <p className='mb-0 fw-bold  ' >Alberttrozzz </p>
                            <p className=' text-slate-600 mb-1 ' >Web developer </p>
                            <img src={require('../../Assest/star.webp')} alt="Star" />
                        </div>
                    </div>
                    <p className='text-slate-500 text-sm line-clamp-3 ' >Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum alias doloribus, nobis, voluptatum iusto asperiores quisquam reprehenderit nihil, aliquid aspernatur atque 
                        inventore. Nihil nesciunt recusandae nemo iure ducimus culpa esse. </p>
                </article>

            </section>



        </div>
    )
}

export default ServiceWorkProcess