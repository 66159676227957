import React, { useEffect, useRef, useState } from 'react'
import LineIcon from '../../SVG/LineIcon'
import RightArrow from '../../SVG/RightArrow'
import { useNavigate } from 'react-router-dom'

const Sectors = ({ bg, text, section, scrollup, scrolldown, heading, content }) => {
    let navigate = useNavigate()
    let scrollTo = (value) => {
        const faqSection = document.getElementById(value); // Get the FAQ section element by its ID
        faqSection.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
    let scrollTo1 = (value) => {
        // alert(value)
        const faqSection = document.getElementById(value); // Get the FAQ section element by its ID
        if (faqSection)
            faqSection.scrollIntoView({ behavior: 'smooth', });
        else
            alert('emoty')
    }
    return (
        <div id={`${section}`} className={` container sticky top-44 sm:top-40 xl:top-32  `} >
            <main className={` ${bg ? bg : "bg-slate-200"} z-5 p-3 p-sm-5 poppins
             min-h-[82vh] rounded-t-[40px] row  ${text ? text : "text-white "}`} >


                <div className=' flex gap-2 items-center' >
                    <LineIcon /> Service
                </div>
                <h4 className='text-3xl sm:text-4xl ' >
                    {heading} </h4>

                <article className='col-lg-6 ' >
                    {
                        content && content.map((obj) => (
                            <div>
                                <h5> {obj.title} </h5>
                                <p className='text-sm w-4/5 '>
                                    {obj.para}
                                </p>
                            </div>
                        ))
                    }
                </article>
                <article className='col-lg-6 ' >
                    <img src={require('../../Assest/service2.webp')} className='' alt="Service" />

                </article>
                {/* <article className='flex gap-2 ' >
                    {scrollup && <button onClick={() => scrollTo(scrollup)} className='rounded-full w-fit h-fit -rotate-90 border-1 p-2 ' >
                        <RightArrow />
                    </button>}
                    {scrolldown && <button onClick={() => scrollTo1(scrolldown)}
                        className='rounded-full w-fit h-fit rotate-90 border-1 p-2 ' >
                        <RightArrow />
                    </button>}
                </article> */}

            </main>

        </div>
    )
}

export default Sectors