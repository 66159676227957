import React, { useEffect, useState } from 'react'
import { slaport } from '../../App'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import CalenderIcon from '../../SVG/CalenderIcon'
import ClockIcon from '../../SVG/ClockIcon'

const EventsSection = () => {
    let [events, setEvents] = useState()
    useEffect(() => {
        axios.get(`${slaport}/event/EventsView/`).then((response) => {
            console.log(response.data, 'event');
            setEvents(response.data)
        }).catch((error) => {
            console.log(error);
        })
    }, [])
    let navigate = useNavigate()
    function formatDate(dateString) {
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

        const date = new Date(dateString);
        const day = date.getDate();
        const month = months[date.getMonth()];
        const year = date.getFullYear().toString().slice(-2);

        // Adding suffix to day (st, nd, rd, th)
        const suffix = (day) => {
            if (day > 3 && day < 21) return 'th'; // 11th to 20th
            switch (day % 10) {
                case 1: return 'st';
                case 2: return 'nd';
                case 3: return 'rd';
                default: return 'th';
            }
        };

        return `${day}${suffix(day)}, ${month} ${year}`;
    }
    function formatTime(timeString) {
        const [hours, minutes, seconds] = timeString.split(':');
        let hour = parseInt(hours);
        const period = hour >= 12 ? 'PM' : 'AM';
    
        hour = hour % 12 || 12; // Convert to 12-hour format
        return `${hour} ${period}`;
    }
    return (
        <div className='bgevent' >
            <main className='container mx-auto ' >


                <p className='text-blue-700 fw-bold mb-2 mt-5 ' >Events </p>
                <p className='text-2xl sm:text-4xl michroma '>
                    Some words <br /> from our clients </p>
            </main>
            <section className='container mx-auto    ' >
                {/* <img src={require('../../Assest/bgtech.webp')} alt="BgTech" className='z-0 absolute' /> */}
                <main className='py-4 row justify-around ' >
                    {
                        events && [...events].slice(0, 4).map((obj) => (
                            <div className='col-sm-6 p-3 relative ' >
                                <span className='absolute top-4 right-4 bg-blue-700 text-white text-xs p-1 px-2 
                                rounded-se-md ' >
                                    {obj.status}

                                </span>
                                <div
                                    onClick={() => navigate(`/events/${obj.slug}`)}
                                    className='p-3 cursor-pointer flex gap-3 shadow bg-white rounded ' >
                                    <img src={obj.events_img ? obj.events_img : require('../../Assest/DashboardLight.webp')}
                                        alt="Image" className='w-[7rem] h-[7rem] object-cover rounded ' />
                                    <section className='' >
                                        <p className='text-teal-400 fw-semibold mb-1 line-clamp-1 ' >{obj.heading} </p>
                                        <p className='text-slate-500 text-sm line-clamp-2 h-[43px] ' >
                                            {obj.content ? obj.content[0].paragraph : ''}
                                        </p>
                                        <div className='text-xs ' >
                                            <p className=' mb-1 flex gap-2 items-center ' >
                                                <CalenderIcon size={12} />
                                                {obj.starting_date && formatDate(obj.starting_date)} - {obj.ending_date && formatDate(obj.ending_date)}
                                            </p>
                                            <p className=' mb-1 flex gap-2 items-center ' >
                                                <ClockIcon size={12} />
                                                {obj.starting_time && formatTime(obj.starting_time)} - {obj.ending_time && formatTime(obj.ending_time)}
                                            </p>


                                        </div>
                                    </section>
                                </div>
                            </div>
                        ))
                    }
                </main>


            </section>

        </div>
    )
}

export default EventsSection