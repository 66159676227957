import React from 'react'
import ArrowRght from '../../SVG/ArrowRght'

const HomeTestimonial = () => {
    return (
        <div className='testimonialbg py-5 ' >
            <main className='container mx-auto ' >

                <button className='rounded bg-rose-500 text-white p-2 px-4 ' >
                    Contact us
                </button>
                <p className='text-blue-700 fw-bold mb-2 mt-5 ' >Testimonials </p>
                <p className='text-2xl sm:text-4xl michroma '>
                  What Our Clients <br /> Say About Us </p>

                <section className='my-4 flex items-center gap-3 ' >
                    <p className='text-2xl poppins mb-0' >01 <span className='text-slate-500 ' > /03 </span>  </p>
                    <div className='w-[150px] border-t-2 border-green-300 relative ' >
                        <div className='w-6 h-6 top-1/2 moveright -translate-y-1/2 
                        absolute border-2 border-green-300 rounded-full' >
                            <div className='w-3 h-3 bg-green-300 rounded-full absolute right-1/2 
                            translate-x-1/2 top-1/2 -translate-y-1/2 ' >

                            </div>

                        </div>

                    </div>
                </section>


                <section className='rounded-xl row w-full p-5 bg-white ' >
                    <div className=' col-sm-5 min-h-[80vh] sm:min-h-[10vh] ' >
                        <p className='text-xl ' >
                            Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam
                            consequat sunt nostrud amet.
                        </p>
                        <img src={require('../../Assest/quotes.png')} className='w-28 flex ms-auto ' alt="Quotes" />
                        <h5 className='text-3xl ' >Simon Árpád </h5>
                        <p className='text-slate-400 text-sm ' >Global Accountability Officer </p>
                    </div>
                    <div className='col-sm-7 relative ' >
                        <img src={require('../../Assest/testimonial.png')} alt="Testimonial"
                            className='absolute w-[18rem] md:w-[20rem] xl:w-[25rem] -bottom-24 right-5 ' />

                        <button className='p-2 flex absolute -right-10 sm:-right-20 bottom-6 items-center 
                        justify-between px-3 w-20 rounded-full bg-rose-600 text-white text-sm ' >
                            Next  <ArrowRght size={6} />
                        </button>
                    </div>
                </section>
            </main>

        </div>
    )
}

export default HomeTestimonial