import React from 'react'

const EasyToManage = () => {
    return (
        <div className='py-5 poppins ' >
            <main className='container mx-auto  ' >
                <h5 className='text-center mx-auto text-3xl sm:text-4xl ' >Easy to Manage <br />
                    at once your all Employee </h5>


                <section className='w-full h-full relative col-lg-10 mx-auto ' >
                    <img src={require('../../Assest/circles.webp')} alt="circle" className=' w-[350px] sm:w-[500px]  mx-auto ' />

                    <img src={require('../../Assest/comp1.webp')} alt="Comp1"
                        className='w-[120px] floating absolute 
                        left-1/3
                        top-20 sm:left-1/2 -translate-x-1/2 ' />

                    <img src={require('../../Assest/comp2.webp')} alt="Comp1"
                        className='w-[120px] floating absolute 
                        top-0 left-0
                        sm:top-14 sm:left-20 ' />
                    <img src={require('../../Assest/comp3.webp')} alt="Comp1"
                        className='w-[120px] floating absolute 
                        right-0 top-0
                        sm:top-20 sm:right-20 ' />
                    <img src={require('../../Assest/comp4.webp')} alt="Comp1"
                        className='w-[120px] floating absolute
                        bottom-0 left-0
                         sm:bottom-20 sm:left-10 ' />
                    <img src={require('../../Assest/comp5.webp')} alt="Comp1"
                        className='w-[120px] floating absolute 
                        bottom-6 d-none d-sm-block
                        sm:bottom-14 left-1/3 sm:-translate-x-1/2 ' />
                    <img src={require('../../Assest/comp6.webp')} alt="Comp1"
                        className='w-[120px] floating absolute 
                        bottom-0 right-0
                        sm:bottom-20 sm:right-12 -translate-x-1/2 ' />
                </section>

            </main>
        </div>
    )
}

export default EasyToManage