import React from 'react'
import BankIcon from '../../SVG/BankIcon'
import TestIcon from '../../SVG/TestIcon'
import HrGroup from '../../SVG/HrGroup'
import AgreementIcon from '../../SVG/AgreementIcon'

const WorkProcess = () => {
    return (
        <div className='workprocessbg xl:min-h-[50vh] min-h-[30vh] py-4 ' >
            <main className='container mx-auto text-center ' >

                <h4 className='textgrdservice uppercase w-fit   ' >Work Process </h4>
                <p className='text-xl fw-semibold sm:text-3xl ' >Our Working Process </p>
                <section className='flex flex-wrap ' >
                    {/* section1     */}
                    <div className='flex flex-col items-center w-1/2 sm:w-1/4 p-4  ' >
                        <div className='w-20 h-20 rounded-full relative bg-white shadow flex items-center justify-center text-violet-800 ' >
                            <BankIcon size={34} />
                            <div className='w-7 h-7 rounded-full bg-blue-500
                                 text-slate-50 flex items-center justify-center absolute top-1 -left-2 ' >
                                1
                            </div>
                        </div>
                        <div className='my-2 ' >
                            <h4 className='' >Choose A Service </h4>
                            <p className='text-slate-500 text-sm ' >In a free hour, when our power of choice is untrammeled and  </p>
                        </div>
                    </div>
                    {/* section 2     */}
                    <div className='flex flex-col items-center w-1/2 sm:w-1/4 p-4  ' >
                        <div className='my-2 ' >
                            <h4 className='' >Define Requirements </h4>
                            <p className='text-slate-500 text-sm ' >In a free hour, when our power of choice is untrammeled and  </p>
                        </div>
                        <div className='w-20 h-20 rounded-full relative bg-white shadow flex items-center justify-center text-violet-800 ' >
                            <TestIcon size={34} />
                            <div className='w-7 h-7 rounded-full bg-blue-500
                                 text-slate-50 flex items-center justify-center absolute top-1 -left-2 ' >
                                2
                            </div>
                        </div>

                    </div>
                    {/* section 3     */}
                    <div className='flex flex-col items-center w-1/2 sm:w-1/4 p-4  ' >
                        <div className='w-20 h-20 rounded-full relative bg-white shadow flex items-center justify-center text-violet-800 ' >
                            <HrGroup size={34} />
                            <div className='w-7 h-7 rounded-full bg-blue-500
                                 text-slate-50 flex items-center justify-center absolute top-1 -left-2 ' >
                                3
                            </div>
                        </div>
                        <div className='my-2 ' >
                            <h4 className='' >Request A Meeting </h4>
                            <p className='text-slate-500 text-sm ' >In a free hour, when our power of choice is untrammeled and  </p>
                        </div>
                    </div>
                    {/* section 4    */}
                    <div className='flex flex-col items-center w-1/2 sm:w-1/4 p-4  ' >
                        <div className='my-2 ' >
                            <h4 className='' >Final Solution </h4>
                            <p className='text-slate-500 text-sm ' >In a free hour, when our power of choice is untrammeled and  </p>
                        </div>
                        <div className='w-20 h-20 rounded-full relative bg-white shadow flex items-center justify-center text-violet-800 ' >
                            <AgreementIcon size={34} />
                            <div className='w-7 h-7 rounded-full bg-blue-500
                                 text-slate-50 flex items-center justify-center absolute top-1 -left-2 ' >
                                4
                            </div>
                        </div>

                    </div>



                </section>

            </main>
        </div>
    )
}

export default WorkProcess