import React, { useContext, useEffect, useState } from 'react'
import ArrowRght from '../../SVG/ArrowRght'
import RightArrow from '../../SVG/RightArrow'
import TagIcon from '../../SVG/TagIcon'
import MessageIcon from '../../SVG/MessageIcon'
import ServiceBlogCard from '../Cards/ServiceBlogCard'
import { HrStorage } from '../../Context/HrStore'
import { useNavigate } from 'react-router-dom'

const LatestNews = () => {
    let { allblog } = useContext(HrStorage)
    console.log(allblog, 'allblog');
    let [entireBlog, setEntireBlog] = useState()
    useEffect(() => {
        setEntireBlog(allblog)
    }, [allblog])
    function formatDate(dateString) {
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

        const date = new Date(dateString);
        const day = date.getDate();
        const month = months[date.getMonth()];

        return `${day} ${month}`;
    }
    let navigate = useNavigate()
    return (
        <div className='my-5 xl:min-h-[80vh] ' >
            <main className='container my-3 mx-auto poppins ' >
                <section className='flex justify-between items-center ' >
                    <div>
                        <h5 className='uppercase textgrdservice text-lg ' >Latest news </h5>
                        <p className='text-2xl sm:text-4xl fw-semibold ' > Latest News <span className='text-blue-900 ' >&</span> Blog </p>
                    </div>
                    <div className='flex ' >
                        <button onClick={()=>navigate('/blogs')} className='uppercase rounded-full p-3 px-4 text-blue-800 bg-sky-50 fw-semibold  ' >
                            View All news
                        </button>
                        <button onClick={()=>navigate('/blogs')} className='w-10 h-10 flex items-center justify-center -rotate-45 rounded-full bg-blue-900 text-white  ' >
                            <RightArrow />
                        </button>
                    </div>
                </section>
            </main>
            <main className=' container mx-auto my-3 row ' >
                <section onClick={() => navigate(`/blogs/${entireBlog && entireBlog[entireBlog.length - 1].slug}`)}
                    className='col-md-6 relative rounded px-0 min-h-[50vh] bg-slate-200 ' >
                    {/* date */}
                    <img src={entireBlog && entireBlog[entireBlog.length - 1].img} alt="Blog Image"
                        className='w-full h-full object-cover rounded
                        ' />
                    <div className='w-full h-full absolute top-0 bg-slate-50 bg-opacity-25 ' >
                        <div className='bgindigo absolute left-4
                     rounded text-white text-center flex flex-col top-2 text-sm p-1 w-12 h-12 ' >
                            {entireBlog && entireBlog[entireBlog.length - 1].created_at &&
                                formatDate(entireBlog[entireBlog.length - 1].created_at)}
                        </div>
                        {/* Heading */}
                        <div className='absolute bottom-0 left-4 ' >
                            <div className='poppins text-sm flex gap-2 ' >
                                <p className='flex gap-1 items-center mb-0 ' > <TagIcon /> {entireBlog && entireBlog[entireBlog.length - 1].Blog_Category &&
                                    entireBlog[entireBlog.length - 1].Blog_Category.name} </p>
                                <p className='flex gap-1 items-center mb-0 '> <MessageIcon />Technology </p>
                            </div>
                            <p className='line-clamp-2 fw-semibold text-2xl w-4/5 '  > {entireBlog && entireBlog[entireBlog.length - 1].Main_Title} </p>
                        </div>
                    </div>
                </section>
                <section className='col-md-6' >
                    <ServiceBlogCard data={entireBlog && entireBlog[entireBlog.length - 2]} />
                    <ServiceBlogCard data={entireBlog && entireBlog[entireBlog.length - 3]} />
                </section>
            </main>

        </div>
    )
}

export default LatestNews