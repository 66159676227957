import React from 'react'

const ArrowRght = ({ size }) => {
    return (
        <svg width={size ? size : "9"} height={size ? size + 5 : "14"}
            viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.10042 6.71565L1.07951 1.88868C0.941255 1.75609 0.8652 1.57881 0.8652 1.38979C0.8652 1.20066 0.941255 1.02349 1.07951 0.890686L1.51946 0.467949C1.6575 0.334938 1.84202 0.26172 2.03864 0.26172C2.23527 0.26172 2.41957 0.334938 2.55771 0.467948L8.53591 6.21487C8.6746 6.34809 8.75054 6.5261 8.75 6.71533C8.75054 6.90541 8.67471 7.08321 8.53591 7.21653L2.56328 12.9579C2.42514 13.0909 2.24084 13.1641 2.0441 13.1641C1.84747 13.1641 1.66317 13.0909 1.52492 12.9579L1.08507 12.5352C0.798858 12.26 0.798858 11.8121 1.08507 11.5371L6.10042 6.71565Z" fill="white" />
        </svg>
    )
}

export default ArrowRght