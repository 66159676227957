import React, { useContext, useState } from 'react'
import { Container, Nav, Navbar, Offcanvas } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { HrStorage } from '../../Context/HrStore'
import FacebookIcon from '../../SVG/FacebookIcon'
import TwitterIcon from '../../SVG/TwitterIcon'
import InstagramIcon from '../../SVG/InstagramIcon'
import LinkedInIcon from '../../SVG/LinkedInIcon'
import { industrycontent, serviceContent } from '../Data'

const HomeNavBar = ({ type }) => {
    let [show, setshow] = useState(false)
    let [subNav, setSubNav] = useState('')
    let { active, setactive } = useContext(HrStorage)

    let navigate = useNavigate()
    return (
        <div>
            <Navbar key="lg" expand="lg" className=" xl:min-h-[12vh] py-0">
                <Container fluid>
                    <Navbar.Brand href="/" className=''>
                        {type == 'white' ?
                            <img className='w-[120px] lg:ms-5' src={require("../../Assest/HR_White.webp")} />
                            : <img className='w-[120px] lg:ms-5' src={require("../../Assest/HrConsultenceLogo.png")}
                                alt="logo" />}
                    </Navbar.Brand>
                    <Navbar.Toggle onClick={() => setshow(true)} className={`${type == 'white' && 'bg-white'} `}
                        aria-controls={`offcanvasNavbar-expand-"lg"`} />
                    <Navbar.Offcanvas
                        show={show}
                        onHide={() => setshow(false)}
                        id={`offcanvasNavbar-expand-"lg"`}
                        aria-labelledby={`offcanvasNavbarLabel-expand-"lg"`}
                        placement="end"
                        className="transi">
                        <Offcanvas.Header className='flex justify-between   '>
                            <img onClick={() => navigate("/")} className='w-[150px]' src={require("../../Assest/HrConsultenceLogo.png")} alt="logo" />
                            <img onClick={() => setshow(false)} className='w-[20px] ' src={require("../../Assest/close.png")} alt="close " />
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <Nav className={`${type == 'white' && ' lg:text-slate-50'} 
                            lg:justify-around h-full flex-grow-1 lg:items-center pe-3 `}>
                                <div className=' flex flex-col lg:w-[65%] lg:ms-10 lg:items-center transi urban
                                gap-2 fw-medium text-lg lg:flex-row xl:me-20 justify-between'>
                                    <Nav.Link onClick={() => {
                                        setactive("home"); navigate("/")
                                    }}>
                                        <p className={`${active == 'home' &&
                                            'border-b-2  border-blue-900 '} m-0 lg:mx-3 w-fit ${type == 'white' && ' lg:text-slate-50'} fw-light `}>
                                            Home </p>
                                    </Nav.Link>

                                    <Nav.Link onClick={() => {
                                        setactive("home"); navigate("/about")
                                    }}>
                                        <p className={`${active == 'about' &&
                                            'border-b-2 border-blue-900 '} m-0 lg:mx-3 w-fit ${type == 'white' && ' lg:text-slate-50 '} fw-light `}>
                                            About us </p>
                                    </Nav.Link>
                                    <section className='relative ' >

                                        {/* <Nav.Link onClick={() => {
                                            setactive("home"); navigate("/service")
                                        }}> */}
                                        <p onClick={() => setSubNav('service')}
                                            onMouseEnter={() => setSubNav('service')}
                                            className={`cursor-pointer ${active == 'service' &&
                                                'border-b-2  border-blue-900 '} m-0 lg:mx-3 w-fit ${type == 'white' && ' lg:text-slate-50'} fw-light `}>
                                            Our Services </p>
                                        {/* </Nav.Link> */}
                                        {subNav == 'service' &&
                                            <div onMouseLeave={() => setSubNav('')}
                                                className='relative z-10 text-sm duration-500 lg:absolute bg-white transi  p-2 rounded lg:w-[250px]'>
                                                {
                                                    serviceContent.map((topic, key) => {
                                                        return (
                                                            <Nav.Link onClick={() => { navigate(`/service/${topic.SID}`); setshow(false) }}
                                                                className="urban hover:scale-105 duration-500 ">{topic.title} </Nav.Link>
                                                        )
                                                    })
                                                }
                                            </div>}
                                    </section>

                                    <section className='relative ' >

                                        {/* <Nav.Link onClick={() => {
                                            setactive("home"); navigate("/service")
                                        }}> */}
                                        <p onClick={() => setSubNav('industry')}
                                            onMouseEnter={() => setSubNav('industry')}
                                            className={`cursor-pointer ${active == 'service' &&
                                                'border-b-2  border-blue-900 '} m-0 lg:mx-3 w-fit ${type == 'white' && ' lg:text-slate-50'} fw-light `}>
                                            Industry </p>
                                        {/* </Nav.Link> */}
                                        {subNav == 'industry' &&
                                            <div onMouseLeave={() => setSubNav('')}
                                                className='relative z-10 text-sm duration-500 lg:absolute bg-white transi  p-2 rounded lg:w-[250px]'>
                                                {
                                                    industrycontent.map((topic, key) => {
                                                        return (
                                                            <Nav.Link onClick={() => { navigate(`/industries/${topic.SID}`); setshow(false) }} className="urban hover:scale-105 transi">{topic.title} </Nav.Link>
                                                        )
                                                    })
                                                }
                                            </div>}
                                    </section>

                                    {/* <Nav.Link onClick={() => {
                                        setactive("home"); navigate("/sector")
                                    }}>
                                        <p className={`${active == 'industry' &&
                                            'border-b-2  border-blue-900 '} m-0 lg:mx-3 w-fit ${type == 'white' && ' lg:text-slate-50'} fw-light `}>
                                            Industry </p>
                                    </Nav.Link> */}

                                    <Nav.Link onClick={() => {
                                        setactive("blog"); navigate("/blogs")
                                    }}>
                                        <p className={`${active == 'blog' &&
                                            'border-b-2  border-blue-900 '} m-0 lg:mx-3 w-fit ${type == 'white' && ' lg:text-slate-50'} fw-light `}>
                                            Blog </p>
                                    </Nav.Link>

                                    <Nav.Link onClick={() => {
                                        setactive("home"); navigate("/careers")
                                    }}>
                                        <p className={`${active == 'career' &&
                                            'border-b-2  border-blue-900 '} m-0 lg:mx-3 w-fit ${type == 'white' && ' lg:text-slate-50'} fw-light `}>
                                            Careers </p>
                                    </Nav.Link>

                                    <Nav.Link onClick={() => {
                                        setactive("home"); navigate("/contact")
                                    }}>
                                        <p className={`${active == 'contact' &&
                                            'border-b-2  border-blue-900 '} m-0 lg:mx-3 w-fit ${type == 'white' && ' lg:text-slate-50'} fw-light `}>
                                            Contact </p>
                                    </Nav.Link>

                                </div>
                                {/* Icons */}
                                <section className='flex gap-4 relative flex-wrap my-3 my-lg-0 ' >
                                    <a className={`cursor-pointer ${type == 'white' ? 'text-white' : 'text-slate-800'} `} ><FacebookIcon size={18} /> </a>
                                    <a className={`cursor-pointer ${type == 'white' ? 'text-white' : 'text-slate-800'} `}><TwitterIcon size={18} /> </a>
                                    <a className={`cursor-pointer ${type == 'white' ? 'text-white' : 'text-slate-800'} `}><InstagramIcon size={18} /> </a>
                                    <a className={`cursor-pointer ${type == 'white' ? 'text-white' : 'text-slate-800'} `}><LinkedInIcon size={18} /> </a>

                                </section>
                            </Nav>

                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Container>
            </Navbar>

        </div>
    )
}

export default HomeNavBar