import React from 'react'

const SearchIcon = ({ size }) => {
    return (
        <svg width={size ? size : "24"} height={size ? size : "24"} viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.875 3.75C6.93997 3.75 3.75 6.93997 3.75 10.875C3.75 14.81 6.93997 18 10.875 18C14.81 18 18 14.81 18 10.875C18 6.93997 14.81 3.75 10.875 3.75ZM2.25 10.875C2.25 6.11154 6.11154 2.25 10.875 2.25C15.6385 2.25 19.5 6.11154 19.5 10.875C19.5 15.6385 15.6385 19.5 10.875 19.5C6.11154 19.5 2.25 15.6385 2.25 10.875Z" fill="currentColor" fill-opacity="1" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M15.915 15.913C16.2079 15.6201 16.6827 15.6201 16.9756 15.913L21.5319 20.4693C21.8248 20.7622 21.8248 21.237 21.5319 21.5299C21.239 21.8228 20.7641 21.8228 20.4712 21.5299L15.915 16.9737C15.6221 16.6808 15.6221 16.2059 15.915 15.913Z" fill="currentColor" fill-opacity="1" />
        </svg>

    )
}

export default SearchIcon