import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { HrStorage } from '../../Context/HrStore'
import CalenderIcon from '../../SVG/CalenderIcon'

const PopularPost = () => {
    let { allblog } = useContext(HrStorage)
    let [randomBlogs, setRandomBlog] = useState()
    const getRandomBlog = () => {
        let uniqueNumbers = new Set();
        let total = allblog.length;

        if (total > 3) {
            while (uniqueNumbers.size < 3) {
                let num = Math.floor(Math.random() * total);
                uniqueNumbers.add(num);
            }

            // Convert set to array and map to get blogs
            let newArry = Array.from(uniqueNumbers).map(num => allblog[num]);

            console.log(newArry);
            setRandomBlog(newArry);
        }
    };
    useEffect(() => {
        if (allblog) {
            getRandomBlog()
        }
    }, [allblog])
    let navigate = useNavigate()
    function convertDate(dateStr) {
        // Create a new Date object from the date string
        const date = new Date(dateStr);

        // Define an array of month names
        const months = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];

        // Get the day of the month (1-31)
        const day = date.getDate();

        // Get the month name from the months array (0-11)
        const month = months[date.getMonth()];

        // Get the full year (YYYY)
        const year = date.getFullYear();

        // Return the formatted date string
        return `${month} ${day}, ${year}`;
    }
    return (
        <div className='rounded inter overflow-hidden my-4 '>
            <p className='text-white mb-0 rounded-t p-3 fw-semibold bg-blue-800 '> Popular Post </p>
            <div style={{ backgroundColor: 'rgb(247,247,249)' }} className='rounded-b  bg-opacity-60 p-3 '>
                {randomBlogs &&
                    [...randomBlogs].map((x) => {
                        console.log("he", x);

                        return (
                            <div onClick={() => navigate(`/blogs/${x.slug}`)} className='flex my-2 '>
                                <img src={x.img ? x.img : require('../../Assest/DashboardLight.webp')} loading='lazy'
                                    className='object-cover h-[70px] rounded ' width='80px' alt="Blog Imaeg" />
                                <div className='px-3'>
                                    <p className='text-slate-600 flex gap-2 text-xs items-center'>
                                        <span className='text-blue-800'><CalenderIcon size={12} />
                                        </span> {convertDate(x.created_at.slice(0, 10))} </p>
                                    <p className='text-xs text-black opacity-100 fw-medium '> {x.Main_Title.slice(0, 30)}{x.Main_Title.length > 30 && "..."} </p>
                                </div>

                            </div>
                        )
                    })
                }

            </div>


        </div>
    )
}

export default PopularPost