import React from 'react'

const TagIcon = ({ size }) => {
    return (
        <svg width={size ? size : "20"} height={size ? size : "20"} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_122_4373)">
                <path d="M8.43584 18.1186H8.4343C7.87265 18.1182 7.34448 17.8991 6.9475 17.5014L1.35561 11.9015C0.537712 11.0821 0.537712 9.74878 1.35561 8.92945L8.89354 1.37706C9.67038 0.5987 10.704 0.169922 11.8039 0.169922H16.5863C17.746 0.169922 18.6896 1.11335 18.6896 2.27329V7.04102C18.6896 8.14013 18.2613 9.1733 17.4837 9.95001L9.92233 17.5035C9.52511 17.9002 8.99719 18.1186 8.43584 18.1186ZM11.8039 1.57216C11.0791 1.57216 10.398 1.85469 9.88607 2.3676L2.34804 9.92C2.07536 10.1932 2.07536 10.6376 2.34804 10.9108L7.9399 16.5107C8.07213 16.6431 8.24809 16.7163 8.4354 16.7164H8.43596C8.52797 16.7166 8.61913 16.6986 8.70416 16.6635C8.78918 16.6283 8.8664 16.5766 8.93134 16.5114L16.4927 8.95806C17.0052 8.44624 17.2874 7.76531 17.2874 7.04105V2.27329C17.2874 1.88669 16.9729 1.57216 16.5863 1.57216H11.8039ZM13.6064 7.2863C12.4466 7.2863 11.503 6.34288 11.503 5.18294C11.503 4.02301 12.4466 3.07958 13.6064 3.07958C14.7662 3.07958 15.7098 4.02301 15.7098 5.18294C15.7098 6.34288 14.7662 7.2863 13.6064 7.2863ZM13.6064 4.48182C13.2198 4.48182 12.9053 4.79634 12.9053 5.18294C12.9053 5.56954 13.2198 5.88406 13.6064 5.88406C13.9931 5.88406 14.3075 5.56954 14.3075 5.18294C14.3075 4.79634 13.9931 4.48182 13.6064 4.48182Z"
                    fill="currentColor" />
            </g>
            <defs>
                <clipPath id="clip0_122_4373">
                    <rect width="19.1453" height="19.1453" fill="white" transform="translate(0.71875 0.170898)" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default TagIcon