import React from 'react'
import RightArrow from '../../SVG/RightArrow'
import Slider from 'react-slick'
import HomeServiceCard from '../Cards/HomeServiceCard'
import { useNavigate } from 'react-router-dom'

const OurServices = () => {
    let navigate = useNavigate()
    let service = [
        {
            title: "Make Hiring Easier with Help from the Experts",
            content: `Let experts handle your hiring process, making it easier to find the right people while 
            you concentrate on growing your business.`,
            slug: '/service/RPO'
        },
        {
            title: "Let Us Professionally Handle All Your Complex HR Tasks  ",
            content: `Focus on your business while your HR tasks are taken care of by 
            professionals who know how to handle them.`,
            slug: '/service/RPO'

        },
        {
            title: "Flexible Team to Support Your Project Needs",
            content: `Quickly find skilled workers for short-term projects, providing the flexibility to 
            meet your needs without making long-term commitments.`,
            slug: '/service/RPO'

        },
        {
            title: "Find Great Employees for the Long Run",
            content: `By connecting you with talented individuals, we help you find long-term 
            team members who truly fit your company’s needs and culture.`,
            slug: '/service/RPO'

        },
        {
            title: "Accurate and Timely Payroll for Employee Satisfaction",
            content: `Make sure employees receive their pay accurately and on time, creating greater 
            job satisfaction and a more positive work environment.`,
            slug: '/service/RPO'

        },
        {
            title: "Safe Hiring with Reliable Background Checks",
            content: `Verify candidate backgrounds to help you make smart hiring 
            choices and ensure a safe and trustworthy workplace for everyone.`,
            slug: '/service/RPO'

        },
    ]
    let settings = {
        dots: false,
        arrows: false,
        speed: 2000,
        slidesToShow: 3,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToScroll: 1,
        initialSlide: 0,
        infinite: true,
        responsive: [
            {
                breakpoint: 1040,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 830,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }

    return (
        <div className='servicebg py-5' >
            <main className='row container mx-auto poppins ' >
                <section className='col-lg-3 ' >
                    <p className='text-blue-600 ' >Our Services </p>
                    <p className='text-2xl sm:text-4xl text-white ' >
                       Take a Look at What We Provide 
                         </p>
                    <button className='bg-blue-600 flex gap-2 items-center text-white rounded p-2 ' >
                        See All
                        <RightArrow size={20} />
                    </button>

                </section>
                <section className='col-lg-9 ' >
                    {/* 1 */}
                    <main className='container my-3 ' >
                        <Slider {...settings} >
                            <HomeServiceCard data={service[0]} />
                            <HomeServiceCard data={service[1]} bg='bg-white' />
                            <HomeServiceCard data={service[2]} bg='bg-slate-600' br='border-slate-50' cir='bg-white'
                                txt='text-slate-50' />
                        </Slider>


                    </main>
                    {/* 2 */}
                    <main className='container my-3 ' >
                        <Slider {...settings} >
                            <HomeServiceCard data={service[3]} />
                            <HomeServiceCard data={service[4]} bg='bg-white' />
                            <HomeServiceCard data={service[5]} bg='bg-slate-600' br='border-slate-50' cir='bg-white'
                                txt='text-slate-50' />
                        </Slider>


                    </main>



                </section>

            </main>
        </div>
    )
}

export default OurServices