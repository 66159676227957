import React from 'react'
import HomeNavBar from '../HomeComponent/HomeNavBar'

const BannerCom = () => {
    return (
        <div className='bannerbgservice' >
            <HomeNavBar type="white" />
            <main className='xl:min-h-[50vh] flex flex-col ' >
                <section className='p-5 ' >
                    <div className='poppins col-8 col-lg-7 text-3xl sm:text-5xl lg:text-8xl fw-lighter text-slate-100 ' >The
                        <span className='fw-light mx-auto flex w-fit  ' >Creative </span>
                        <span className='flex ms-auto w-fit' >Agency </span>
                    </div>

                </section>
                <img src={require('../../Assest/design2.webp')} alt="Design" className='w-40 sm:w-[40rem] flex ms-auto mt-auto ' />
            </main>
        </div>
    )
}

export default BannerCom