import React from 'react'
import { useNavigate } from 'react-router-dom';

const HomeBlogCard = ({ data }) => {
    console.log(data, 'blog');
    let navigate = useNavigate()
    return (
        <div className='w-[18rem] relative my-4 mx-auto rounded-xl shadow bg-white min-h-[5rem] ' >
            <div className='relative  ' >
                <img src={data.img ? data.img : require('../../Assest/Figure.png')}
                    className='rounded h-[18rem] object-cover ' alt="Figure" />
                <span className=' absolute left-1/2 w-10 -translate-x-1/2 border-2 border-slate-300 -bottom-3 rounded-full p-2 bg-slate-50' >
                    <img className=' '
                        src={require('../../Assest/favicon.ico')} alt="Icon" />
                </span>
            </div>
            {/* Bottom */}
            <div className='p-3 poppins  text-center relative ' >
                <h5 className='line-clamp-2 ' > {data.Main_Title} </h5>
                <p className='line-clamp-3 text-slate-400 text-sm ' >
                    {data.Paragraph1} </p>
            </div>
            <button onClick={() => navigate(`/blogs/${data.slug}`)} className='p-2 left-1/2 -translate-x-1/2 absolute -bottom-3 px-2 rounded bg-rose-600 text-white text-xs ' >
                Read More
            </button>
        </div>
    )
}

export default HomeBlogCard