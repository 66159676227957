import React from 'react'
import HrSection1 from '../Component/HRpublish/HrSection1'
import WhyHrmsNeed from '../Component/HRpublish/WhyHrmsNeed'
import BoostProductivity from '../Component/HRpublish/BoostProductivity'
import HrSection from '../Component/HRpublish/HrSection'
import WorkSamrter from '../Component/HRpublish/WorkSamrter'
import EasyToManage from '../Component/HRpublish/EasyToManage'
import ClientAboutUs from '../Component/HRpublish/ClientAboutUs'
import { Helmet } from 'react-helmet'

const HrmsPublish = () => {
    return (
        <div>
            <Helmet>
                <title>Empowering Organisations: Merida HR Consultancy</title>
                <meta name="description" content=" Boosting organisations with smart HR solutions. Drive productivity and growth with our expert support" />
                <link rel="canonical" href="https://www.meridahr.com/about" />
            </Helmet>
            <HrSection1 />
            <WhyHrmsNeed />
            <BoostProductivity />
            <HrSection />
            <WorkSamrter />
            <EasyToManage />
            <ClientAboutUs />
        </div>
    )
}

export default HrmsPublish