import React from 'react'

const LineIcon = ({ size }) => {
    return (
        <svg width="28" height="3" viewBox="0 0 28 3" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.671875 2.16743C0.671875 1.18559 1.46781 0.389648 2.44965 0.389648H25.5608C26.5426 0.389648 27.3385 1.18559 27.3385 2.16743V2.16743H0.671875V2.16743Z" fill="currentColor" />
        </svg>


    )
}

export default LineIcon