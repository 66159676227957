import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import BlogDetails from '../Component/Blog/BlogDetails'
import BlogComment from '../Component/Blog/BlogComment'
import axios from 'axios'
import { domain, hrport, slaport } from '../App'
import { Helmet } from 'react-helmet'
import HomeNavBar from '../Component/HomeComponent/HomeNavBar'
import RightSideBlog from '../Component/RightSideBlog'
import { HrStorage } from '../Context/HrStore'


const ParticularBlog = () => {
    let { slug } = useParams()
    let { setactive } = useContext(HrStorage)
    let [blog, setBlog] = useState()
    useEffect(() => {
        setactive('blog')
        window.scrollTo(0, 0)
        if (slug) {
            axios.get(`${slaport}/blog/data/${slug}`).then((response) => {
                console.log(response.data);
                setBlog(response.data)
            }).catch((error) => {
                console.log(error);
            })
        }
    }, [slug])
    return (
        <>
            <HomeNavBar />
            <div className='container mx-auto col-xl-10 '>
                {blog && <> <Helmet>
                    <title> {blog.Main_Title} </title>
                    <meta name="description" content="We are inviting you to know more about SkillLearningAcademy's journey to the success and innovation in training, upskilling and get mentoring support and 
        guidance. " />
                    <link rel="canonical" href={`${domain}/blogs/${slug}`} />
                </Helmet>
                    <h1 className='d-none'>best training company</h1>
                    <h2 className='d-none'>top training institute </h2>
                </>}
                <section className='row my-5'>
                    <div className='col-lg-8 col-xl-9 '>
                        {blog && <BlogDetails data={blog} />}
                        {blog && <BlogComment id={blog.id} />}
                    </div>
                    <div className='col-lg-4 col-xl-3 '>
                        <RightSideBlog />
                    </div>
                </section>
            </div>
        </>
    )
}

export default ParticularBlog