import React from 'react'

const ServiceContent = ({ data }) => {
    return (
        <div className='container mx-auto poppins ' >
            {console.log(data, 'service')}

            <img src={require('../../Assest/service1.webp')} alt="Service" />
            {
                data && data.whatContent && data.whatContent.heading &&
                <h5 className='mt-4 ' >
                    {data.whatContent.heading}
                </h5>
            }
            {
                data && data.whatContent && data.whatContent.para.map((obj, index) => (
                    <p className='my-3 text-slate-600 ' >
                        {obj}
                    </p>
                ))
            }

            <img src={require('../../Assest/service2.webp')} alt="Service" className='my-3 ' />
            {
                data && data.howContent && data.howContent.heading &&
                <h5 className='mt-4 text-2xl fw-semibold' >
                    {data.howContent.heading}
                </h5>
            }
            {
                data && data.howContent && data.howContent.content.map((obj, index) => (
                    <div className='my-3 text-slate-600 ' >
                        <h6 className='text-slate-900 fw-semibold ' >{obj.title} </h6>
                        <p>{obj.para} </p>
                    </div>
                ))
            }

            {/* Benefit */}
            {
                data && data.benefit && data.benefit.heading &&
                <h5 className='mt-4 text-2xl fw-semibold' >
                    {data.benefit.heading}
                </h5>
            }
            {
                data && data.benefit && data.benefit.content.map((obj, index) => (
                    <div className='my-3 text-slate-600 ' >
                        <h6 className='text-slate-900 fw-semibold ' >{obj.title} </h6>
                        <p>{obj.para} </p>
                    </div>
                ))
            }
            {/* WHo */}
            {
                data && data.who && <div>
                    <h5 className='mt-4 text-2xl fw-semibold' >
                        Who is it for?
                    </h5>
                    <ul>

                        {data && data.who.map((val) => (
                            <li className='my-2 list-disc ' > {val} </li>
                        ))}
                    </ul>
                </div>
            }
            {
                data && data.conclusion && <div>
                    {/* <h5 className='mt-4 text-2xl fw-semibold' >
                        Conclusion
                    </h5> */}
                    <p className='' >{data.conclusion} </p>
                </div>
            }

        </div>
    )
}

export default ServiceContent