import React from 'react'
import Slider from 'react-slick'

const ClientAboutUs = () => {
    let settings = {
        className: "center",
        centerMode: true,
        dots: true,
        arrows: false,
        speed: 2000,
        slidesToShow: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToScroll: 1,
        initialSlide: 0,
        infinite: true,
        centerPadding: "160px",
        responsive: [
            {
                breakpoint: 1040,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 830,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    centerMode: false,
                    slidesToScroll: 1
                }
            }
        ]
    }

    return (
        <div className='servicebg poppins ' >
            <main className='relative container-fluid py-5 px-0 min-h-[30vh] clientbg rounded-xl ' >
                <img src={require('../../Assest/design.webp')} alt="Design" className=' w-32 right-20 absolute  ' />
                <img src={require('../../Assest/design.webp')} alt="Design"
                    className=' w-32 left-20 absolute bottom-24 rotate-180  ' />
                {/* Contents */}
                <h5 className=' text-center fw-semibold text-3xl text-blue-600 ' >What Our Clients Say About Us </h5>

                <section className='relative my-4 ' >
                    <Slider {...settings} className='relative w-full  ' >


                        {/* Card */}
                        <div className=' w-3/5 ' >

                            <div className='w-full overflow-hidden rounded-xl bg-white flex h-[300px] mx-auto ' >
                                <img src={require('../../Assest/careerBg.webp')} alt="Comment"
                                    className='w-2/5 sm:w-1/4 h-full object-cover object-right-top ' />
                                <article className='p-4 flex-1  overflow-hidden ' >
                                    <p className='text-slate-500 fw-semibold text-sm ' >May 8, 2020 </p>
                                    <h5 className='line-clamp-2 text-2xl ' >Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis, odio. </h5>
                                    <p className='text-sm line-clamp-5 ' > Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum, eum ab consequatur quas nesciunt architecto minima labore quisquam incidunt aliquam accusamus unde! Vel sunt aliquam neque quas maxime omnis vitae molestiae delectus, repudiandae sit in labore
                                        assumenda beatae nulla error? </p>
                                </article>

                            </div>

                        </div>
                        {/* Card 2*/}
                        <div className=' w-3/5 ' >

                            <div className='w-full overflow-hidden rounded-xl bg-white flex h-[300px] mx-auto ' >
                                <img src={require('../../Assest/careerBg.webp')} alt="Comment"
                                    className='w-2/5 sm:w-1/4 h-full object-cover object-right-top ' />
                                <article className='p-4 flex-1  overflow-hidden ' >
                                    <p className='text-slate-500 fw-semibold text-sm ' >May 8, 2020 </p>
                                    <h5 className='line-clamp-2 text-2xl ' >Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis, odio. </h5>
                                    <p className='text-sm line-clamp-5 ' > Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum, eum ab consequatur quas nesciunt architecto minima labore quisquam incidunt aliquam accusamus unde! Vel sunt aliquam neque quas maxime omnis vitae molestiae delectus, repudiandae sit in labore
                                        assumenda beatae nulla error? </p>
                                </article>

                            </div>

                        </div>

                        {/* Card 3*/}
                        <div className=' w-3/5 ' >

                            <div className='w-full overflow-hidden rounded-xl bg-white flex h-[300px] mx-auto ' >
                                <img src={require('../../Assest/careerBg.webp')} alt="Comment"
                                    className='w-2/5 sm:w-1/4 h-full object-cover object-right-top ' />
                                <article className='p-4 flex-1  overflow-hidden ' >
                                    <p className='text-slate-500 fw-semibold text-sm ' >May 8, 2020 </p>
                                    <h5 className='line-clamp-2 text-2xl ' >Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis, odio. </h5>
                                    <p className='text-sm line-clamp-5 ' > Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum, eum ab consequatur quas nesciunt architecto minima labore quisquam incidunt aliquam accusamus unde! Vel sunt aliquam neque quas maxime omnis vitae molestiae delectus, repudiandae sit in labore
                                        assumenda beatae nulla error? </p>
                                </article>

                            </div>

                        </div>
                    </Slider>
                </section>

            </main>
            {/* Next Section */}
            <main className='py-5 ' >
                 <h5 className='text-white text-center text-3xl sm:text-4xl fw-semibold  ' >We are here to help <br /> you grow your business </h5>
           
           <button className='rounded-full bg-white text-blue-900 fw-semibold p-2 px-4 mx-auto flex my-4 ' >
            Discuss Now 
           </button>
            </main>

        </div>
    )
}

export default ClientAboutUs