import React from 'react'

let serviceContent = [
    {
        "id": 1,
        "SID": "RPO",
        SeoTitle: `Efficient RPO Solutions`,
        SeoDescription: `Discover efficient RPO solutions tailored to your needs. Streamline recruitment processes, save time, and find top talent effortlessly with our expertise.`,
        SeoCanonical: `https://meridahr.com/service/RPO`,
        "icon": "../Assest/human-resources.png",
        "title": "Recruitment Process Outsourcing (RPO)",
        "heading1": "Unlock the Power of Strategic Hiring with Merida HR Consulting",
        "para1": `Are you in search of a trustworthy and capable way to make your recruiting work better? Well, Merida HR Consulting is here for you. If you are looking for `,
        link: `Recruitment Process Outsourcing `,
        alink: "https://en.wikipedia.org/wiki/Recruitment#Recruitment_Process_Outsourcing",
        para11: `(RPO) then look no further. Merida HR Consulting could change 
        how you build your workforce if only given an opportunity.
        `,
        // "heading2": "Why Choose Merida HR Consulting for RPO?",
        whatContent: {
            heading: 'What is Recruitment Process Outsourcing (RPO)?',
            para: [`Recruitment Process Outsourcing (RPO) is a practical solution that allows companies to
                 hand over all or part of their hiring tasks to outside experts. In simple terms, RPO providers 
                 become an extension of your HR team. This means everything from spotting job openings to finding 
                 candidates and running interviews can be managed by an RPO provider. These professionals specialize in hiring, 
                 which means they have the right tools and know how to connect you with the best talent quickly. By working with our expert RPO providers, businesses can focus on what they do best while leaving the recruitment process in capable hands. 
                This not only saves time but also helps ensure that the candidates you get are a great fit for your company culture and job needs.`,
                `RPO is great for businesses of all sizes. Whether you’re a startup trying to build your team or a well-established company looking to improve how you hire, RPO can adapt to your needs. Its flexibility is a big plus! For example, if you’re experiencing a busy season or launching a new project, RPO can step up to help fill those positions quickly. And when things slow down, they can easily scale back. In short, RPO is a smart way for organizations to enhance their hiring processes, cut costs, and find better candidates. 
            It helps companies find great talent and build a strong team that supports long-term success.`]
        },
        howContent: {
            heading: 'How Recruitment Process Outsourcing Works',
            content: [
                {
                    title: 'Understanding Your Needs',
                    para: `Our expert RPO team starts by learning about your business, the roles you need to fill, 
                    and your company culture. This helps them discover the best match for you.`
                },
                {
                    title: 'Candidate Sourcing',
                    para: `We use different tools and platforms to search for qualified candidates. With our experience, we know exactly where to look and how to 
                    find the best people for the job.`
                },
                {
                    title: 'Screening & Interviewing',
                    para: `They take care of the initial screening and conduct interviews. This means only the most suitable candidates 
                    are presented to you for final selection.`
                },
                {
                    title: 'Offer & Onboarding',
                    para: `Once you choose a candidate, RPO providers will help with the job offer and onboarding process. 
                    This ensures a smooth start for the new hire.`
                },
                {
                    title: 'Continuous Improvement',
                    para: `Our RPO service includes looking at each hiring process to find ways to make it better. 
                    We adjust strategies to improve results for future hiring.`
                },
            ]
        },
        benefit: {
            heading: 'Benefits of RPO',
            content: [
                {
                    title: 'Cost-Effective',
                    para: `Using RPO services can save your company money. With our in-house system, you get top talent 
                    without the expense of a large recruitment team.`
                },
                {
                    title: 'Time-Saving',
                    para: `Hiring can take a lot of time. With experts handling it, you can fill open positions quickly, 
                    allowing you to focus on other important tasks.`
                },
                {
                    title: 'Access to Top Talent',
                    para: `Our RPO team connects you with a large network of candidates. 
                    They can find talented individuals who may not apply through regular channels.`
                },
                {
                    title: 'Scalable Solutions',
                    para: `Whether you need to bring on a single employee or a whole team, our RPO services can be customized to suit your requirements. 
                    They scale up or down based on your hiring needs.`
                },
                {
                    title: 'Improved Quality of Hire',
                    para: `With a dedicated RPO team, you'll find it easier to hire great candidates. 
                    They focus on finding candidates who truly match your company’s needs and values.`
                },
            ]
        },
        who: [
            'Growing companies that are looking to bring more people onto their teams.',
            'Businesses aiming to lower HR expenses while maintaining effective hiring practices.',
            'Organizations that need quicker methods to find and hire the right people.',
            'Companies that are having a tough time finding suitable candidates for jobs.',
            'Startups that don’t have enough human resources support to hire effectively.',
            'Businesses that have hiring needs based on specific projects or timelines.'
        ],
        conclusion: 'Let Merida HR Consulting simplify your hiring with our expert RPO services. Contact us to get started and start hiring the best talent today! ',


        // "heading4": "Experience the Merida HR Consulting Difference Today!",
        // "heading4content": "Don't let recruitment challenges hold your business back. Partner with Merida HR Consulting for a reliable, efficient, and cost-effective Recruitment Process Outsourcing solution. Contact us today to schedule a consultation and take the first step towards building a high-performing team that drives your success.",
        "img1": "../Assest/rpo1.jpeg",
        "img2": "../Assest/rpo2.jpg",
        "img3": "../Assest/rpo3.jpg"
    },
    {
        "id": 2,
        "SID": "Contract-Staffing-Solutions",
        "title": "Contract Staffing Solutions",
        SeoTitle: `Contract Staffing Solutions`,
        SeoDescription: `Explore our contract staffing solutions for flexible workforce needs. We offer tailored services to match your requirements and streamline hiring processes effectively.`,
        SeoCanonical: `https://meridahr.com/service/Contract-Staffing-Solutions`,
        "icon": "../Assest/contract.png",
        "heading1": "Welcome to  Merida HR Consulting – Your Trusted Partner in Contract Staffing",
        "para1": `At  Merida HR Consulting, we understand the dynamic nature of today's business environment. That's why we offer cutting-edge Contract`,
        link: ` Staffing `,
        alink: "https://en.wikipedia.org/wiki/Staffing",
        para11: `solutions designed to meet your immediate staffing needs with flexibility and efficiency.
        `,
        "heading2": "Why Choose  Merida HR Consulting for Contract Staffing?",
        whatContent: {
            heading: 'What is Contract Staffing Solutions?',
            para: [`Contract Staffing Solutions is a simple and flexible way for companies to bring in talented workers for short-term or project-based tasks without the long-term commitment. At Merida HR Consulting, we help you quickly find the right people to get the job done. Whether you're dealing with sudden surges in workload, launching a new project, or just need some extra hands, contract staffing lets you hire skilled professionals only when you need
                 them.`,
                `Instead of committing to full-time hires, companies can utilize contract staffing to scale up or down as needed. This is particularly beneficial for businesses looking to minimize HR costs while maintaining productivity. By working with Merida HR Consulting, you can work with access to highly skilled candidates across various industries. We manage the recruitment, selection, and onboarding processes, allowing you to focus on your business. With our Contract Staffing Solutions, your company can stay flexible, save time, and get the job done right,
                 every time.`]
        },
        howContent: {
            heading: 'How Contract Staffing Solutions Work',
            content: [
                {
                    title: 'Understanding Your Requirements',
                    para: `We take the time to learn about your project, the skills required, and your deadlines. This allows us to identify candidates who are the perfect match 
                    for your needs.`
                },
                {
                    title: 'Candidate Sourcing',
                    para: `Using our wide network and recruiting tools, we quickly search for qualified professionals
                     who are perfect for the job.`
                },
                {
                    title: 'Screening & Selection',
                    para: `We handle the initial interviews and assessments so you only meet the candidates 
                    who are a great fit for your project.`
                },
                {
                    title: 'Hiring & Onboarding',
                    para: `Once you’ve chosen the right person, we assist with the paperwork and onboarding to ensure a quick and 
                    easy start.`
                },
                {
                    title: 'Ongoing Support',
                    para: `Throughout the contract period, we offer continuous support and monitor performance, 
                    making adjustments to meet your needs.`
                },
            ]
        },
        benefit: {
            heading: 'Benefits of Contract Staffing Solutions',
            content: [
                {
                    title: 'Cost-Effective',
                    para: `Hire skilled professionals only when needed, saving on full-time salaries and benefits, 
                    keeping your costs low and manageable.`
                },
                {
                    title: 'Quick Turnaround',
                    para: `Fill project roles to meet deadlines and keep your projects on track, without the hassle of 
                    lengthy hiring processes.`
                },
                {
                    title: 'Access to Specialized Skills',
                    para: `Connect with professionals who have specific industry expertise, ensuring you get the right skills 
                    for your project’s unique needs.`
                },
                {
                    title: 'Scalable Staffing',
                    para: `Easily increase or reduce your team size as project demands change, 
                    without committing to long-term employment contracts.`
                },
                {
                    title: 'Reduced Risk',
                    para: `Use contract workers for short-term projects to avoid the risks of long-term commitments and reduce 
                    hiring-related complications.`
                },
            ]
        },
        who: [
            'Companies seeking short-term assistance to complete projects.',
            'Businesses looking to save on HR costs while staying productive.',
            'Organizations with fluctuating staffing needs based on project timelines.',
            'Startups that need temporary workers without long-term commitments.',
            'Firms needing quick access to skilled professionals for urgent work.',
            'Companies looking for fast access to talented professionals for specific tasks.'
        ],
        conclusion: 'Let Merida HR Consulting handle your project staffing needs with our expert Contract Staffing Solutions. Get in touch with us today and start building the perfect team!',


        // "heading4": "Experience the  Merida HR Consulting Advantage Today!",
        // "heading4content": "Ready to build your workforce with flexible, skilled professionals? Partner with  Merida HR Consulting for Contract Staffing solutions that drive efficiency, innovation, and success. Contact us today to discuss your staffing needs and take the first step towards building a dynamic and high-performing team.",
        "img1": "../Assest/ContractStaffingSolutions1.jpeg",
        "img2": "../Assest/ContractStaffingSolutions2.jpeg",
        "img3": "../Assest/ContractStaffingSolutions3.jpeg"
    },
    {
        "id": 3,
        "SID": "Permanent-Staffing",
        SeoTitle: `Permanent Staffing Solutions: Find Your Ideal Team`,
        SeoDescription: `Secure long-term success with our permanent staffing solutions. We connect you with top talent to build a thriving team.`,
        SeoCanonical: `https://meridahr.com/service/Permanent-Staffing`,
        "title": "Permanent Staffing",
        "icon": "../Assest/profile.png",
        "heading1": "Welcome to  Merida HR Consulting – Your Premier Partner in Permanent Staffing",
        "para1": `Merida HR’s permanent staffing services for clients involve managing the entire recruitment
process to find and place employees who are intended to be a long-term part of the client's
organization. Here’s an overview of how Merida HR might approach permanent staffing for
clients`,
        // link: ` Permanent Staffing `,    
        // alink: "https://en.wikipedia.org/wiki/Permanent_staff_instructor",
        // para11: `solutions are crafted to connect you with top-tier talent that aligns seamlessly with your company's vision and goals.
        // `,
        // "heading2": "Why Choose  Merida HR Consulting for Permanent Staffing?",
        whatContent: {
            heading: 'What is Permanent Staffing?',
            para: [`Permanent Staffing is all about finding the right employees who will stick around and grow with your company. Unlike temporary workers, these individuals become part of your team, helping to shape your company’s future. At Merida HR Consulting, we know that hiring the right people is essential for a thriving workplace. We focus on connecting you with candidates who not only have the necessary skills but also contribute positively 
                to your work environment.`,
                `We take the time to understand what makes your company unique and what you need in a new hire. This means we look beyond just qualifications. We consider personality, work ethic, and team dynamics to ensure a perfect fit. This way, you can enjoy a stable workforce, reduce turnover, and increase productivity. With our Permanent Staffing services, your hiring process will be efficient, effective, 
                and tailored to your specific requirements.`]
        },
        howContent: {
            heading: 'How Permanent Staffing Works',
            content: [
                {
                    title: 'Understanding Your Needs',
                    para: `We begin by meeting with you to understand your company's goals, values, and specific staffing requirements. This helps us get a clear picture of what you’re 
                    looking for in a candidate.`
                },
                {
                    title: 'Sourcing Candidates',
                    para: `We handle initial interviews and assessments to ensure that candidates meet your expectations. 
                    This saves you time and allows you to focus on the best matches.`
                },
                {
                    title: 'Hiring Process',
                    para: `Once you choose a candidate, we assist with all hiring paperwork and formalities. Our goal is to make the onboarding process as smooth as 
                    possible for both you and the new employee.`
                },
                {
                    title: 'Follow-Up Support',
                    para: `After hiring, we stay in touch to ensure that both you and the employee are happy. Our ongoing support helps address any concerns and 
                    builds a successful working relationship.`
                },
            ]
        },
        benefit: {
            heading: 'Benefits of Permanent Staffing',
            content: [
                {
                    title: 'Long-Term Commitment',
                    para: `Hiring permanent employees leads to stability within your team. This reduces turnover and results in much better company culture.`
                },
                {
                    title: 'Better Culture Fit',
                    para: `We focus on finding candidates who match your company’s values and environment. 
                    This promotes collaboration and enhances job satisfaction for all employees.`
                },
                {
                    title: 'Reduced Turnover Costs',
                    para: `With dedicated full-time employees, your team can work more efficiently. 
                    This results in higher productivity and better quality of work for your projects. `
                },
                {
                    title: 'Increased Productivity',
                    para: `A stable team leads to better collaboration and higher productivity, which contributes to better quality 
                    of work for your projects and overall business success.`
                },
                {
                    title: 'Access to Top Talent',
                    para: `Our recruitment process ensures you have access to highly skilled professionals eager to join your 
                    team.`
                },
            ]
        },
        who: [
            'Companies looking for dedicated employees who fit their culture.',
            'Organizations needing to build a strong, stable workforce for growth.',
            'Businesses wanting to reduce turnover and improve team dynamics.',
            'Firms that prioritize long-term investments in their staff.',
            'Startups aiming for stability with reliable, full-time team members.',
            'Companies focused on enhancing productivity through committed staff.'
        ],
        conclusion: `Let Merida HR Consulting help you find the perfect permanent employees for your team with our Permanent Staffing services. Reach out to us today to get started on building a strong, 
        talented workforce!`,

        // "heading4": "Partner with  Merida HR Consulting for Exceptional Permanent Staffing Solutions",
        // "heading4content": "Ready to build a lasting, high-performing team? Choose  Merida HR Consulting as your trusted partner for Permanent Staffing solutions. Contact us today to discuss your hiring needs and embark on a journey to secure top-tier talent that drives your business forward.",
        "img1": "../Assest/PermanentStaffing1.jpeg",
        "img2": "../Assest/PermanentStaffing2.jpeg",
        "img3": "../Assest/PermanentStaffing3.jpeg"
    },
    {
        "id": 4,
        "SID": "Payroll-Processing",
        SeoTitle: `Payroll Processing Made Easy: Trusted Solutions`,
        SeoDescription: `Secure long-term success with our permanent staffing solutions. We connect you with top talent to build a thriving team.`,
        SeoCanonical: `https://meridahr.com/service/Payroll-Processing`,
        "title": "Payroll Processing",
        "icon": "../Assest/salary.png",
        "heading1": "Welcome to  Merida HR Consulting – Your Reliable Partner in Seamless Payroll Processing",
        "para1": `At  Merida HR Consulting, we understand the critical role that payroll plays in your business. Our comprehensive `,
        link: ` Payroll `,
        alink: "https://en.wikipedia.org/wiki/Payroll",
        para11: `Processing services are designed to take the burden off your shoulders, ensuring accuracy, compliance, and efficiency in managing your payroll functions.
        `,
        whatContent: {
            heading: 'What is Payroll Processing?',
            para: [`Payroll Processing is the process of managing employee compensation, ensuring that wages are calculated accurately and delivered on time. It involves monitoring hours, calculating pay, deducting taxes, and processing payments. Payroll processing also covers regulatory compliance, ensuring all payments meet local, state, and federal regulations. By handling payroll efficiently, businesses avoid costly errors and maintain employee 
                satisfaction.`,
                `At Merida HR Consulting, we offer expert payroll processing services that simplify your payroll operations, ensuring accuracy and timeliness. We handle the details so your business can focus on growth, not payroll issues. Whether it’s managing salaries, bonuses, or tax withholdings, our team makes sure your employees are paid correctly and on time, every time. This leads to happier employees, reduced turnover, and a smoother workflow. Accurate payroll processing is essential for maintaining trust between your business and your 
                    team.`]
        },
        howContent: {
            heading: 'How Payroll Processing Works?',
            content: [
                {
                    title: 'Understanding Payroll Requirements',
                    para: `We begin by reviewing your company’s payroll needs, salary structure, tax laws, and compliance requirements for 
                    accurate processing.`
                },
                {
                    title: 'Tracking Employee Hours',
                    para: `We monitor employee working hours and attendance using reliable tools, ensuring accurate wage 
                        calculations for each pay period.`
                },
                {
                    title: 'Calculating Wages',
                    para: `We calculate wages, taking into account hours worked, overtime, bonuses, and deductions, ensuring
                     correct payments for every employee.`
                },
                {
                    title: 'Issuing Payments',
                    para: `Payments are issued promptly through direct deposit or checks, ensuring employees are paid on time without 
                    any delays.`
                },
            ]
        },
        benefit: {
            heading: 'Why Outsource Payroll?',
            content: [
                {
                    title: 'Cost-Effective',
                    para: `Outsourcing helps cut down on payroll mistakes, preventing costly fines and allowing your business to save money in the 
                    long run.`
                },
                {
                    title: 'Time-Saving',
                    para: `With our expert payroll team handling everything, you save time and can focus more on other 
                    important business tasks.`
                },
                {
                    title: 'Accuracy Guaranteed',
                    para: `We ensure your employees get paid the correct amount every time, 
                    reducing any mistakes that could cause problems. `
                },
                {
                    title: 'Keeping Up with the Rules',
                    para: `Our team stays updated on changing laws, so you don’t have to worry about being 
                    caught up with the regulations or tax rules.`
                },
                {
                    title: 'Happier Employees',
                    para: `When employees are paid correctly and on time, they feel valued and satisfied, 
                    leading to a more positive work environment.`
                },
            ]
        },
        who: [
            'Businesses that need accurate payroll to ensure timely and correct employee payments.',
            'Companies aiming to minimize payroll mistakes and improve overall management efficiency.',
            'Growing companies without a dedicated team to handle their payroll processes.',
            'Organizations looking for affordable and efficient payroll management services.',
            'Startups focusing on growth by outsourcing payroll responsibilities to experts.',
            'Businesses ensuring they meet all tax and labor law requirements seamlessly.'
        ],
        conclusion: `Let Merida HR Consulting manage your payroll with precision and care. Get in touch with us today to make payroll easier and keep your team happy.`,
        // "heading4": "Partner with  Merida HR Consulting for Effortless Payroll Processing",
        // "heading4content": "Experience the ease and efficiency of outsourcing your payroll processing to  Merida HR Consulting. Contact us today to explore how our tailored payroll solutions can benefit your business, allowing you to focus on what you do best.",
        "img1": "../Assest/payroll1.jpeg",
        "img2": "../Assest/payroll2.jpeg",
        "img3": "../Assest/payroll3.jpeg"
    },
    {
        "id": 5,
        "SID": "background-verification",
        SeoTitle: `Background Verification Solutions: Find Your Ideal Team`,
        SeoDescription: `Secure long-term success with our permanent staffing solutions. We connect you with top talent to build a thriving team.`,
        SeoCanonical: `https://meridahr.com/service/background-verification`,
        "title": "Background Verification",
        "icon": "../Assest/profile.png",
        "heading1": "Welcome to  Merida HR Consulting – Your Premier Partner in Permanent Staffing",
        "para1": `Merida HR’s permanent staffing services for clients involve managing the entire recruitment
process to find and place employees who are intended to be a long-term part of the client's
organization. Here’s an overview of how Merida HR might approach permanent staffing for
clients`,
        // link: ` Permanent Staffing `,    
        // alink: "https://en.wikipedia.org/wiki/Permanent_staff_instructor",
        // para11: `solutions are crafted to connect you with top-tier talent that aligns seamlessly with your company's vision and goals.
        // `,
        // "heading2": "Why Choose  Merida HR Consulting for Permanent Staffing?",
        whatContent: {
            heading: 'What is Background Verification?',
            para: [`Background Verification is a crucial part of the hiring process. It involves checking a candidate's personal, educational, and work history to ensure they are the right fit for your company. This process helps you confirm the information candidates provide, making sure that everything is accurate and honest. By conducting thorough background checks, businesses can avoid hiring individuals with 
                misleading or false information.`,
                `At Merida HR Consulting, we understand the importance of safe hiring which starts with reliable background checks. Our expert team is dedicated to helping you create a trustworthy workplace by ensuring the backgrounds of all potential employees are verified. This includes checking educational qualifications, previous employment, criminal records, and other relevant information. Our approach ensures you receive a complete picture of the candidate’s history. With our help, you can focus on building your team with confidence, 
                knowing that every hire has been thoroughly verified.`]
        },
        howContent: {
            heading: 'How Background Verification Works',
            content: [
                {
                    title: 'Gathering Information',
                    para: `We start by collecting essential details about the candidate, including personal and professional 
                    information needed for thorough verification.`
                },
                {
                    title: 'Document Verification',
                    para: `Our team carefully verifies all educational certificates, employment records, and any other 
                    documents the candidate provides to confirm authenticity.`
                },
                {
                    title: 'Criminal Record Check',
                    para: `We perform a detailed search for any past criminal activity, helping you ensure a safe and secure workplace environment
                     for all employees.`
                },
                {
                    title: 'Employment History Check',
                    para: `Our experts review the candidate's previous work experiences to validate the accuracy of the information they provided 
                    during the hiring process.`
                },
                {
                    title: 'Final Report',
                    para: `After completing all checks, we provide you with a clear and detailed report that helps you make informed hiring decisions with 
                    confidence.`
                },

            ]
        },
        benefit: {
            heading: 'Benefits of Background Verification',
            content: [
                {
                    title: 'Safe Hiring',
                    para: `Verify candidates to ensure they have clean backgrounds and do not pose any threat to your workplace, promoting a secure environment for 
                    everyone.`
                },
                {
                    title: 'Trustworthy Workforce',
                    para: `Build a team of reliable employees by confirming that all hires have genuine backgrounds
                     and a history of honesty in their work.`
                },
                {
                    title: 'Accurate Information',
                    para: `Avoid making hiring mistakes by checking that the details provided by candidates are truthful and correct, preventing future
                     issues in your company. `
                },
                {
                    title: 'Better Hiring Decisions',
                    para: `Make informed choices with thorough background checks, helping you select the best candidates who fit your company’s 
                    values and needs effectively.`
                },
                {
                    title: 'Reduced Risk',
                    para: `Lower the chance of hiring individuals with unsafe or fraudulent histories, which helps protect your 
                    business and maintains a safe working environment.`
                },
            ]
        },
        who: [
            'Businesses looking to hire reliable employees for a strong workforce.',
            'Companies focused on reducing hiring risks and improving selection accuracy.',
            'Organizations that need fast and reliable background checks for candidates.',
            'Startups lacking their own resources for conducting verification processes effectively.',
            'Employers wanting to create a safer work environment for all employees.',
            'Firms requiring background verification services for specific roles or projects.'
        ],
        conclusion: `Reach out to us now to get professional background verification services.
         Ensure safe hiring and protect your workplace with our expert checks!`,

        // "heading4": "Partner with  Merida HR Consulting for Exceptional Permanent Staffing Solutions",
        // "heading4content": "Ready to build a lasting, high-performing team? Choose  Merida HR Consulting as your trusted partner for Permanent Staffing solutions. Contact us today to discuss your hiring needs and embark on a journey to secure top-tier talent that drives your business forward.",
        "img1": "../Assest/PermanentStaffing1.jpeg",
        "img2": "../Assest/PermanentStaffing2.jpeg",
        "img3": "../Assest/PermanentStaffing3.jpeg"
    },

]

let industrycontent = [
    {
        "id": 1,
        "SID": "information-technology",
        "icon": "../Assest/human-resources.png",
        "title": "Information Technology",
        bannerHeading:'Redefining Your IT Hiring Approach',
        bannertext:'Partner with us to find top IT talent, enhance employee satisfaction, and build a skilled, compliant workforce.',
        "heading1": "Unlock the Power of Strategic Hiring with Merida HR Consulting",
        "para1": `When Merida HR caters to IT clients, they would focus on providing tailored staffing solutions that
 address the specific needs and challenges of the IT industry. Here’s how Merida HR might structure
 its services for IT clients`,
        "para2": `Hospitality industry is a growing industry in India and is going to touch $460 billion by 2028.
        There is an increase in people traveling within India and also foreign visitors coming to visit India.
        `,
        "para3": `Hospitality industry includes hotels and hotel chains, independent hotels and service apartments,budget and economy hotels, mid and upper and  mid scale  hotels and luxury 
        hotels.`,
        "para4": `The hospitality industry is growing and there is demand by people for hotel and    accommodation.This is because people are traveling  more for their jobs and on  business trips and also for entertainment 
        and sightseeing purposes, outdoor and recreation, and tourism purposes.`,
        "para5": `This brings about a need for more hospitality business.Thus hospitality industry requires more manpower to run their businesses.
        `,
        "para6": `Whether you require permanent staff or contract staff or recruitment process outsourcing, meridahr can help you.We can also help in grievances of staff and payroll processing of your employees.We 
        are up to  date with the trends in the hospitality industry and know the manpower requirement for the hospitality industry. Merida can help you in recruiting and staffing employees so that you get skilled manpower as we carefully select skilled staff for your manpower 
        needs..`,
        // "heading2": "Why Choose Merida HR Consulting for RPO?",
        "overview": {
            heading: 'Industry Overview',
            content: [{
                title: '',
                para: `The Information Technology (IT) Industry plays a huge role in our everyday lives. It includes everything from creating software to ensuring our online data stays safe. With technology being a big part of how businesses run, having skilled IT professionals is more important than ever. Companies rely on these experts to develop new programs, protect sensitive information, and keep systems working 
                smoothly.`
            }, {
                title: '',
                para: `However, finding the right tech talent can be a real challenge. As technology changes quickly, businesses often struggle to keep up. That’s where Merida HR Consulting comes in. We specialize in helping companies in the IT industry with their unique HR needs. Our goal is to help you find the best talent, provide valuable training, and create a happy work 
                environment.`
            }]
        },
        "challenges": {
            heading: 'Common HR Challenges',
            content: [
                {
                    title: 'Talent Shortage',
                    para: `It's hard to find skilled professionals in tech. Many companies find it challenging to bring in candidates who have the right skills and 
                experience.`
                }, {
                    title: 'Employee Retention',
                    para: `Keeping talented employees is a challenge. Frequent employee turnover can result in losing important skills and 
                expertise.`
                },
                {
                    title: 'Skills Gap',
                    para: `Technology constantly evolves, requiring new and updated skills. Companies often find it difficult to keep their staff's skills 
                    updated.`
                }, {
                    title: 'Compliance Issues',
                    para: `Understanding employment laws and regulations can be tricky. Companies need help to ensure they meet these legal 
                    requirements.`
                }, {
                    title: 'Training and Development',
                    para: `Rapid changes in technology require ongoing training. It's crucial to help them learn new skills to stay 
                    relevant.`
                },
            ]
        },
        "benefit": {
            heading: 'Benefits of Working with Us',
            content: [
                {
                    title: 'Expert Matching',
                    para: `We connect you with highly skilled IT professionals quickly and efficiently.`
                }, {
                    title: 'Tailored Solutions',
                    para: `Our services are customized to meet your specific hiring and HR needs.`
                },
                {
                    title: 'Cost-Effective',
                    para: `Reduce your hiring and training costs with our effective HR solutions.`
                }, {
                    title: 'Supportive Growth',
                    para: `We help your team improve skills, enhancing overall performance and satisfaction.`
                }, {
                    title: 'Risk-Free Hiring',
                    para: `Our thorough screening process ensures you hire skilled candidates without the hassle.`
                },
            ]
        },
        "solutions": {
            heading: 'Our Solutions',
            content: [
                {
                    title: 'Targeted Recruitment',
                    para: `We focus on connecting your company with the right IT professionals. Our recruitment process is designed to help you connect with the best 
                    candidates quickly.`
                }, {
                    title: 'Retention Strategies',
                    para: `We create plans to improve employee satisfaction and loyalty. Happy employees are more likely to stick around and contribute to your 
                    success.`
                },
                {
                    title: 'Employee Training',
                    para: `We offer tailored training programs to keep your staff updated on the latest technology. Our goal is to help them grow and succeed in 
                    their roles.`
                }, {
                    title: 'Compliance Support',
                    para: `We provide expert guidance on employment laws. Our support ensures your business stays compliant and avoids legal 
                    troubles.`
                }, {
                    title: 'Skill Development Programs',
                    para: `We offer programs designed to bridge the gap between current skills and future needs. Our focus is on helping your team stay ahead in their 
                    careers.`
                },
            ]
        },
        conclusion: `Don’t let HR challenges hold your IT business back. Partner with Merida HR Consulting for specialized HR solutions tailored to your needs. 
        Let us help you find the right talent and improve your team 
        today!`,
        "img1": "../Assest/rpo1.jpeg",
        "img2": "../Assest/rpo2.jpg",
        "img3": "../Assest/rpo3.jpg"
    },
    {
        "id": 2,
        "SID": "pharmaceutical",
        "title": "Pharmaceutical",
        bannerHeading:'Bringing the Best to Pharma Teams with HR Solutions',
        bannertext:'We help build strong pharmaceutical teams with HR solutions that find and keep the right people for you.',
        "icon": "../Assest/contract.png",
        "heading1": "Welcome to  Merida HR Consulting – Your Trusted Partner in Contract Staffing",
        "para1": `When Merida HR caters to the pharmaceutical industry, the focus is on addressing the unique staffing needs of a sector that involves specialized knowledge, compliance with stringent regulations, and a commitment to innovation and patient care. Here’s how Merida HR might tailor its services for pharmaceutical 
        clients `,
        "para2": `The USA accounts for more than a quarter of Indian pharmaceutical exports.Around 30% of  the generic medicines supplied to the USA are from India.Some of the major countries India  
        exports are USA, UK, South Africa, Russia and Nigeria.
        `,
        "para3": `India has allowed 100 percent FDI for foreign companies. India has around 3000 pharma companies with 10,500 manufacturing facilities.
        `,
        "para4": `Some of the major pharma companies  in India are Sun, Cipla,Zydus,Pfizer,Torrent Pharmaceuticals, Dr Reddys, etc.
        `,
        "para5": `Indian pharmaceutical industry growth started with the introduction of patents act in 1970 and drug policy of 1978.These acts set up a stage  for indian companies to establish themselves in the international markets.The 1984 Hatch-Waxman Act in the US boosted the production  of generic drugs benefitting indian drug makers.
        `,
        "para6": `A large volume of imported medical devices and supplies will be made in India.With the opening of the economy in 1991 and removal of licensing restrictions ,helped Indian Pharmaceutical companies establish themselves on the global platform.
        `,
        "para7": `This growth has brought about immense job opportunities for pharma professionals in India.
        `,
        // "heading2": "Why Choose  Merida HR Consulting for Contract Staffing?",
        "overview": {
            heading: 'Industry Overview',
            content: [{
                title: '',
                para: `The pharmaceutical sector focuses on preparing medications that help people live healthier lives. From developing new drugs to making sure they are safe for everyone, this field is crucial in advancing healthcare. Pharmaceutical companies are involved in various activities, including research, testing, and distribution. They work hard to bring new treatments to market and improve the quality of care for patients 
                everywhere.`
            }, {
                title: '',
                para: `However, this industry faces unique challenges, especially when it comes to HR solutions for pharmaceutical companies. Finding and keeping talented people is critical to success. With constant advancements in science and technology, companies need skilled professionals who can help them stay ahead. Effective human resource management is essential for ensuring that these companies have the right people in place to continue their 
                important work.`
            }]
        },
        "challenges": {
            heading: 'Common HR Challenges',
            content: [
                {
                    title: 'Talent Acquisition',
                    para: `Finding qualified candidates for specialized roles in pharmaceutical companies is tough due to high 
                    competition.`
                }, {
                    title: 'Employee Retention',
                    para: `Keeping skilled employees is challenging, as professionals seek better opportunities and work 
                    environments.`
                },
                {
                    title: 'Training for Change',
                    para: `Continuous employee training is essential to keep staff updated on the latest developments in medicines and 
                    healthcare.`
                }, {
                    title: 'Workforce Planning',
                    para: `Properly managing staff levels and skills can be complex, affecting overall productivity and 
                    operations.`
                }, {
                    title: 'Performance Management',
                    para: `Setting clear performance goals and measuring success in specialized roles is necessary for growth and employee 
                    satisfaction.`
                },
            ]
        },
        "benefit": {
            heading: 'Benefits of Working with Us',
            content: [
                {
                    title: 'Expertise in Pharma HR',
                    para: `We offer specialized knowledge in HR practices tailored for the pharmaceutical industry.`
                }, {
                    title: 'Improved Hiring Processes',
                    para: `Our recruitment strategies streamline hiring, helping you find the right candidates faster.`
                },
                {
                    title: 'Employee Development',
                    para: `We focus on training that enhances employee skills, leading to better performance and satisfaction.`
                }, {
                    title: 'Reduced Turnover Rates',
                    para: `Our retention strategies help keep your talent engaged and loyal, reducing hiring costs.`
                }, {
                    title: 'Compliance Assurance',
                    para: `We ensure your HR practices align with industry standards, minimizing risks and legal issues.`
                },
            ]
        },
        "solutions": {
            heading: 'Our Solutions',
            content: [
                {
                    title: 'Expert Recruitment',
                    para: `We provide pharma recruitment services to help you find the right people for specialized pharmaceutical roles, ensuring a perfect match for your 
                    needs.`
                }, {
                    title: 'Customized Training',
                    para: `Our training programs are designed to fit your team’s needs, focusing on employee training in pharmaceuticals to help them grow in their 
                    roles.`
                },
                {
                    title: 'Effective Workforce Management',
                    para: `We assist in managing your workforce to ensure you have the right skills in the right places, enhancing workforce 
                    management.`
                }, {
                    title: 'Performance Tools',
                    para: `Our systems help you assess employee performance in a fair and supportive way, resulting in growth within your 
                    team.`
                }, {
                    title: 'Support with Industry Standards',
                    para: `We guide you through the necessary HR practices to align with industry expectations, allowing you to focus on your 
                    mission.`
                },
            ]
        },
        conclusion: `See how our Pharmaceutical HR services can make a real difference. We’re ready to help you address HR challenges and enhance your business. Get in touch with us today to find out how our support can truly benefit 
            your company.`,
        // "heading4": "Experience the  Merida HR Consulting Advantage Today!",
        "heading4content": "By focusing on these areas, Merida HR can effectively support pharmaceutical clients in finding and managing the right talent to drive innovation, maintain compliance, and achieve business objectives within the highly regulated and dynamic pharmaceutical sector.",
        "img1": "../Assest/ContractStaffingSolutions1.jpeg",
        "img2": "../Assest/ContractStaffingSolutions2.jpeg",
        "img3": "../Assest/ContractStaffingSolutions3.jpeg"
    },
    {
        "id": 3,
        "SID": "healthcare",
        "title": "Health Care",
        "icon": "../Assest/profile.png",
        bannerHeading:'Making Healthcare Recruitment Simple and Effective',
        bannertext:'We provide expert HR solutions to connect healthcare organizations with the skilled professionals they need for quality care.',
        "heading1": "Welcome to  Merida HR Consulting – Your Premier Partner in Permanent Staffing",
        "para1": `The E- Commerce market in India is growing rapidly.With access to internet and, internet connections being cheaper there are more people using the internet.With  lot of people using smartphones, the internet access is within fingertip reach now.
        `,
        "para2": `The  other major boost to e-commerce is businesses going online and giving competitive prices online.Thus there is increase in online shopping.People are buying clothes, gadgets, groceries, books etc online.
        `,
        "para3": `The major online market breakup is business to business (b2b), Business to consumer ( b2c),
        Consumer to consumer(c2c) and others.Digital wallet plays an important role in  e-commerce payment.With the increase of internet usage and smartphones the  digital wallet transactions have increased.
        `,
        "para4": `This is possible due to advancement of technology, govt initiatives and changes in consumer preferences.
        `,
        "para5": `This has led to more  ecommerce businesses and growth in e-commerce jobs and related jobs.Now there is increase of online purchases in tier 1 and tier 2  cities in India.
        `,
        "para6": `Since there is still a lower  penetration of  e-commerce there is  automatic scope  and for growth.This has  led to a demand for e-commerce jobs.The Indian e-commerce market size
        Is set to reach US$ 259 billion by 2032 ( source: IMARC).
        `,
        "para7": `Some of the top  e-commerce companies are Amazon, Flipkart,Indiamart, Firstcry etc.
        `,
        "overview": {
            heading: 'Industry Overview',
            content: [{
                title: '',
                para: `The Healthcare Industry plays a crucial role in keeping our communities healthy. It includes hospitals, clinics, and various healthcare providers who deliver essential services to patients. With a focus on care and well-being, this industry relies on skilled professionals who ensure that every patient receives the best 
                treatment.`
            }, {
                title: '',
                para: `As the demand for healthcare staffing solutions continues to grow, organizations need dedicated staff to meet these needs. However, finding qualified professionals can be challenging. Merida HR Consulting specializes in providing tailored HR services specifically for healthcare institutions. We help you build strong teams, provide ongoing training, and ensure that your staff meets all necessary requirements to deliver high-quality 
                care.`
            }]
        },
        "challenges": {
            heading: 'Common HR Challenges',
            content: [
                {
                    title: 'Talent Shortage',
                    para: `Finding qualified healthcare professionals is tough. Lots of companies find it tough to hire people who have the right skills and experience for the 
                    job.`
                }, {
                    title: 'Staff Retention',
                    para: `Keeping skilled staff can be difficult. High turnover leads to lost expertise and affects patient care 
                    quality.`
                },
                {
                    title: 'Training Gaps',
                    para: `Healthcare practices and technologies change often. Staff need ongoing training to stay up-to-date with the latest 
                    developments.`
                }, {
                    title: 'Regulatory Challenges',
                    para: `Understanding healthcare regulations can be complex. Organizations need support to meet all necessary requirements for 
                    operations.`
                }, {
                    title: 'Work Environment',
                    para: `Creating a positive workplace is essential. A supportive environment helps retain staff and improves overall patient 
                    satisfaction.`
                },
            ]
        },
        "benefit": {
            heading: 'Benefits of Working with Us',
            content: [
                {
                    title: 'Expert Recruitment',
                    para: `We connect you with skilled healthcare professionals quickly and effectively.`
                }, {
                    title: 'Customized Training',
                    para: `Our training programs are designed to meet the specific needs of your staff.`
                },
                {
                    title: 'Enhanced Staff Retention',
                    para: `We help create a supportive environment that keeps employees happy and engaged.`
                }, {
                    title: 'Compliance Assistance',
                    para: `Our guidance ensures your organization meets all necessary healthcare regulations.`
                }, {
                    title: 'Cost-Effective Solutions',
                    para: `We provide affordable HR services that save your organization time and money.`
                },
            ]
        },
        "solutions": {
            heading: 'Our Solutions',
            content: [
                {
                    title: 'Targeted Recruitment',
                    para: `We help connect you with qualified healthcare professionals. Our recruitment process ensures you find the right candidates for your needs through medical recruitment 
                    services.`
                }, {
                    title: 'Employee Engagement',
                    para: `We develop strategies to keep your staff motivated. Happy employees contribute more and stay longer in their 
                    roles.`
                },
                {
                    title: 'Training Programs',
                    para: `Our customized healthcare training programs help your team learn new skills. We focus on areas that improve patient care and staff 
                    efficiency.`
                }, {
                    title: 'Guidance on Regulations',
                    para: `We provide support to help you understand healthcare laws. Our expert guidance helps keep your organization in good 
                    standing.`
                }, {
                    title: 'Skills Development',
                    para: `Our programs fill skill gaps, ensuring your team is ready for the future challenges. We focus on helping your team grow in their 
                    careers.`
                },
            ]
        },
        conclusion: `Don’t let HR challenges hold your healthcare organization back. Partner with Merida HR Consulting for specialized HR solutions personalized to your needs. 
        Let us help you discover the right talent and strengthen your team!`,
        "heading4": "Partner with  Merida HR Consulting for Exceptional Permanent Staffing Solutions",
        "heading4content": "Ready to build a lasting, high-performing team? Choose  Merida HR Consulting as your trusted partner for Permanent Staffing solutions. Contact us today to discuss your hiring needs and embark on a journey to secure top-tier talent that drives your business forward.",
        "img1": "../Assest/PermanentStaffing1.jpeg",
        "img2": "../Assest/PermanentStaffing2.jpeg",
        "img3": "../Assest/PermanentStaffing3.jpeg"
    },
    {
        "id": 4,
        "SID": "finance-and-banking",
        "title": "Finance and Banking",
        "icon": "../Assest/salary.png",
        bannerHeading:'HR Solutions Made Easy for Finance',
        bannertext:'Let’s work together to create strong, dedicated teams in the banking sector.',
        "overview": {
            heading: 'Industry Overview',
            content: [{
                title: '',
                para: `The finance and banking industry provides essential services and helps people manage their money, save for the future, and get loans for homes or businesses. This sector ensures the smooth flow of capital, which helps individuals and businesses achieve their financial goals. With technology changing how we bank, it’s important for financial companies to have skilled workers who can adapt to new tools while providing great 
                service.`
            }, {
                title: '',
                para: `As banks and financial institutions grow, effective management of their workforce is essential. Banks and financial firms need to ensure their employees are trained, satisfied, and productive. This requires a tailored approach to workforce management, compliance training, and performance evaluations. At Merida HR Consulting, we understand the unique needs of this industry and are here to help businesses manage their human resources 
                smoothly.`
            }]
        },
        "challenges": {
            heading: 'Common HR Challenges',
            content: [
                {
                    title: 'Talent Shortage',
                    para: `Finding skilled professionals is a major issue. Many finance companies struggle to recruit candidates with the right qualifications and 
                    experience.`
                }, {
                    title: 'High Turnover',
                    para: `Frequent changes in staff can disrupt daily operations. Losing experienced employees affects the quality of service offered to 
                    clients.`
                },
                {
                    title: 'Compliance Training',
                    para: `Staying up-to-date with rules and regulations is necessary. Many companies find it challenging to train employees on the latest compliance 
                    requirements.`
                }, {
                    title: 'Technology Adaptation',
                    para: `New financial technologies require continuous learning. Many employees need training to stay updated with these 
                    advancements.`
                }, {
                    title: 'Performance Tracking',
                    para: `Evaluating employee performance can be complex. Without clear systems, assessing productivity and effectiveness becomes 
                    difficult.`
                },
            ]
        },
        "benefit": {
            heading: 'Benefits of Working with Us',
            content: [
                {
                    title: 'Expert Support',
                    para: `Gain professional help to address your HR needs efficiently and effectively.`
                }, {
                    title: 'Tailored Services',
                    para: `We customize our services to address the particular needs of your business.`
                },
                {
                    title: 'Cost Efficiency',
                    para: `Save on hiring and training costs with our effective HR strategies.`
                }, {
                    title: 'Enhanced Training',
                    para: `Improve employee skills with our focused training programs.`
                }, {
                    title: 'Increased Productivity',
                    para: `Enhance overall performance with our targeted development initiatives.`
                },
            ]
        },
        "solutions": {
            heading: 'Our Solutions',
            content: [
                {
                    title: 'Targeted Recruitment',
                    para: `We help you find the best talent for your finance and banking needs. Our recruitment strategies are tailored for your specific 
                    requirements.`
                }, {
                    title: 'Retention Strategies',
                    para: `We develop programs to enhance employee satisfaction. When employees feel valued, they are more likely to remain and contribute 
                    positively.`
                },
                {
                    title: 'Training Programs',
                    para: `We offer essential training for compliance and technology. This keeps your team knowledgeable and confident in their 
                    roles.`
                }, {
                    title: 'Skill Development',
                    para: `We conduct training sessions to support employees in gaining new skills. Continuous learning is important in this changing 
                    industry.`
                }, {
                    title: 'Performance Management Systems',
                    para: `We set up simple systems to track employee performance. This helps you easily measure how well your team is 
                    doing.`
                },
            ]
        },
        conclusion: `If you are ready to address your HR challenges in the finance and banking sector, partner with Merida HR Consulting today! Let us assist you in building a stronger team and improving your 
        business outcomes.`,
        // "heading4": "Partner with  Merida HR Consulting for Effortless Payroll Processing",
        "heading4content": "By focusing on these areas, Merida HR can effectively support banking clients in finding and managing the right talent to meet the complex and evolving demands of the financial sector.",
        "img1": "../Assest/payroll1.jpeg",
        "img2": "../Assest/payroll2.jpeg",
        "img3": "../Assest/payroll3.jpeg"
    },
    {
        "id": 5,
        "SID": "engineering-and-r&d",
        "title": "Engineering and R&D",
        "icon": "../Assest/salary.png",
        bannerHeading:'Innovative HR Solutions for Engineering and R&D',
        bannertext:'Connect with top talent and help your engineering and R&D teams grow with our dedicated support.',
        "heading1": "Welcome to  Merida HR Consulting – Your Reliable Partner in Seamless Payroll Processing",
        "para1": `When Merida HR caters to the ITES (Information Technology Enabled Services) industry, the focus is on recruiting and managing talent for services that combine IT with business processes. ITES encompasses areas like customer support, business process outsourcing (BPO), data management, and more. Here’s how Merida HR might tailor its services for ITES 
        clients`,
        "overview": {
            heading: 'Industry Overview',
            content: [
                {
                    title: '',
                    para: `The Engineering and Research & Development (R&D) sectors are essential for creating new ideas and improving technology. Engineering companies design products, while R&D teams explore innovative solutions. These fields are crucial for industries like construction, manufacturing, and technology. Skilled workers are needed to tackle complex problems and develop practical 
                solutions.`
                }, {
                    title: '',
                    para: `Attracting the right talent can be challenging due to strong competition. Companies must provide engaging work environments and growth opportunities to attract skilled professionals. Effective staffing and teamwork are essential for meeting project deadlines and maintaining quality. By focusing on hiring and developing talent, businesses can build strong teams that drive success and 
                innovation.`
                },
                {
                    title: '',
                    para: `At Merida HR Consulting, we offer personalized HR solutions for engineering firms and R&D institutions. Our services focus on helping you find and develop the talent you 
                need.`
                },
            ]
        },
        "challenges": {
            heading: 'Common HR Challenges',
            content: [
                {
                    title: 'Finding Qualified Individuals',
                    para: `It’s hard to choose the right people with specialized skills in engineering and R&D fields that meet your project 
                    needs.`
                }, {
                    title: 'Project Staffing',
                    para: `Getting the right mix of team members for projects is crucial. Poor staffing can cause delays and lead to 
                    frustration.`
                },
                {
                    title: 'Skill Development',
                    para: `Keeping employees’ skills up-to-date is essential. Continuous learning ensures they know the latest tools and technologies in the 
                    industry.`
                }, {
                    title: 'High Turnover Rates',
                    para: `Many companies face issues with employees leaving frequently. High turnover disrupts project timelines and negatively impacts team 
                    spirit.`
                }, {
                    title: 'Diversity and Inclusion',
                    para: `Creating diverse teams is important for fresh ideas. Many firms struggle to build welcoming environments that attract various 
                    talents.`
                },
            ]
        },
        "benefit": {
            heading: 'Benefits of Working with Us',
            content: [
                {
                    title: 'Expert Guidance',
                    para: `Get support from experienced HR consultants who understand the engineering and R&D industries well.`
                }, {
                    title: 'Tailored Services',
                    para: `Receive HR solutions designed specifically for your company’s unique needs, helping you achieve your goals.`
                },
                {
                    title: 'Cost-Effective',
                    para: `Save on hiring and training costs with our effective HR methods, designed to help your business succeed.`
                }, {
                    title: 'Team Improvement',
                    para: `Enhance your team's skills with our focused training programs, leading to better performance and productivity.`
                }, {
                    title: 'Get Innovative',
                    para: `Attract diverse talent to encourage teamwork and generate fresh, innovative ideas.`
                },
            ]
        },
        "solutions": {
            heading: 'Our Solutions',
            content: [
                {
                    title: 'Custom Recruitment',
                    para: `We help you create a tailored recruitment plan to find the best talent suited for your engineering and R&D 
                    projects.`
                }, {
                    title: 'Flexible Staffing Solutions',
                    para: `Our staffing services let you adjust your team size based on project demands, ensuring you always have the right 
                    support.`
                },
                {
                    title: 'Training Programs',
                    para: `We provide training sessions that keep your staff updated on new skills and technologies, helping them grow in their 
                    roles.`
                }, {
                    title: 'Retention Strategies',
                    para: `We develop strategies to make your workplace supportive, helping to keep your best employees happy and 
                    engaged.`
                }, {
                    title: 'Diversity Initiatives',
                    para: `We help implement diversity programs that encourage inclusive hiring, bringing in different perspectives to enhance 
                    creativity.`
                },
            ]
        },
        conclusion: `Find the top talent for your engineering and R&D projects with Merida HR Consulting. Our personalized HR services connect you with the right talent. Get in touch now to learn more about our Engineering and R&D HR 
        services!`,
        // "heading4": "Partner with  Merida HR Consulting for Effortless Payroll Processing",
        "heading4content": "By focusing on these areas, Merida HR can effectively support ITES clients in finding and managing the right talent to drive their business success and operational efficiency.",
        "img1": "../Assest/rpo1.jpeg",
        "img2": "../Assest/rpo2.jpg",
        "img3": "../Assest/rpo3.jpg"
    },
    {
        "id": 6,
        "SID": "logistics",
        "title": "Logistics",
        bannerHeading:'Connecting You to Top Talent in Logistics',
        bannertext:'Enhance your logistics operations by connecting with the right talent and building effective teams with our HR solutions.',
        "icon": "../Assest/salary.png",
        "overview": {
            heading: 'Industry Overview',
            content: [
                {
                    title: '',
                    para: `The logistics and supply chain industry is all about getting products from one place to another. This includes everything from transportation and storage to managing inventory and ensuring timely deliveries. As online shopping and fast shipping become the norm, the demand for skilled workers in logistics is growing. However, this industry faces some tough challenges in managing its people 
                    effectively.`
                }, {
                    title: '',
                    para: `With various roles, like truck drivers, warehouse workers, and supply chain managers, having the right talent is crucial for smooth operations. Companies need to ensure they have skilled individuals in every position to keep everything running efficiently. Workforce planning and training programs are essential to help staff succeed in this 
                    busy environment.`
                },
                {
                    title: '',
                    para: `Moreover, technology is changing how logistics operates. With new tools and systems popping up, companies need employees who can adapt quickly. At Merida HR Consulting, we’re here to provide tailored HR services that make it easier for logistics companies to 
                    operate.`
                },
            ]
        },
        "challenges": {
            heading: 'Common HR Challenges',
            content: [
                {
                    title: 'Talent Shortage',
                    para: `Finding skilled workers for logistics roles is challenging, as the demand for talent exceeds the available supply in today's job
                     market.`
                }, {
                    title: 'High Turnover Rates',
                    para: `Many logistics positions experience high employee turnover, which leads to constant hiring and training costs for 
                    companies.`
                },
                {
                    title: 'Training Gaps',
                    para: `New technologies require continuous employee training, but many workers lack the necessary skills to use these systems 
                    effectively.`
                }, {
                    title: 'Performance Management',
                    para: `Monitoring employee performance effectively can be challenging in a fast-paced environment with diverse roles and 
                    responsibilities.`
                }, {
                    title: 'Employee Burnout',
                    para: `The pressure of meeting tight deadlines often causes employee burnout and dissatisfaction, impacting productivity and 
                    retention.`
                },
            ]
        },
        "benefit": {
            heading: 'Benefits of Working with Us',
            content: [
                {
                    title: 'Expert Guidance',
                    para: `Our team knows the logistics industry well, ensuring our solutions fit your specific needs.`
                }, {
                    title: 'Tailored Services',
                    para: `We customize our services to match your company's requirements, promoting better results for your business.`
                },
                {
                    title: 'Improved Efficiency',
                    para: `Our strategies streamline operations, allowing your team to focus on what they do best.`
                }, {
                    title: 'Cost Savings',
                    para: `We help you save money in the long run by reducing turnover and enhancing training efforts.`
                }, {
                    title: 'Increased Employee Satisfaction',
                    para: `Our commitment to employee well-being leads to a happier, more engaged workforce, benefiting your entire organization.`
                },
            ]
        },
        "solutions": {
            heading: 'Our Solutions',
            content: [
                {
                    title: 'Workforce Planning',
                    para: `We help determine your staffing requirements and develop tailored plans to place the right employees in the right 
                    positions.`
                }, {
                    title: 'Customized Training',
                    para: `We offer tailored training programs to enhance employee skills and knowledge specific to logistics and supply chain 
                    tasks.`
                },
                {
                    title: 'Performance Monitoring Tools',
                    para: `We provide tools and systems to track and evaluate employee performance effectively, ensuring continuous 
                    improvement.`
                }, {
                    title: 'Retention Strategies',
                    para: `We develop strategies aimed at improving employee satisfaction and reducing turnover through engagement and support 
                    initiatives.`
                }, {
                    title: 'Supportive Work Environment',
                    para: `We help create a positive workplace culture that minimizes burnout and fosters productivity and collaboration among 
                    employees.`
                },
            ]
        },
        conclusion: `Partner with Merida HR Consulting today and see how our tailored services can transform your operations. Contact us now to learn more about our Logistics HR services and start benefiting 
        immediately!`,
        "img1": "../Assest/payroll2.jpeg",
        "img2": "../Assest/payroll2.jpeg",
        "img3": "../Assest/payroll3.jpeg"
    },
    {
        "id": 7,
        "SID": "retail",
        "title": "Retail",
        bannerHeading:'Building Strong Retail Teams Through HR Solutions',
        bannertext:'Our HR solutions simplify the hiring process, creating a robust retail team that delivers excellent customer service.',
        "icon": "../Assest/salary.png",
        "heading1": "Welcome to  Merida HR Consulting – Your Reliable Partner in Seamless Payroll Processing",
        "overview": {
            heading: 'Industry Overview',
            content: [
                {
                    title: '',
                    para: `The retail industry is all around us. It includes all the stores and online shops where we buy what we want. From clothing to groceries to much more, retailers connect us with products every day.As shopping habits shift, especially towards online shopping, retail businesses must adapt to meet customer expectations. During busy times, like holidays, retailers often hire extra staff. This seasonal hiring helps keep customers happy and ensures everyone gets what 
                    they need.`
                }, {
                    title: '',
                    para: `However, managing a retail team can be tough. Many stores struggle with high employee turnover. When workers leave often, it can hurt service quality. To keep staff engaged, retailers should focus on employee training. This helps everyone learn how to serve customers better. Tracking employee performance is also key. It helps managers see who is doing well and who might need help. By focusing on these areas, retail businesses can create a happy workplace and keep good employees longer. At Merida HR Consulting, we offer specialized HR services for the retail sector. Let us help your business build a strong team that improves customer 
                    satisfaction.`
                },
            ]
        },
        "challenges": {
            heading: 'Common HR Challenges',
            content: [
                {
                    title: 'Seasonal Hiring',
                    para: `Retailers often face the challenge of finding temporary staff during peak seasons. This can be difficult due to fluctuating customer 
                    demand.`
                }, {
                    title: 'Employee Training',
                    para: `Training employees in customer service skills is essential. Many retail workers require ongoing training to ensure they meet customer 
                    expectations.`
                },
                {
                    title: 'Adapting to Technology',
                    para: `As retail technology advances, employees need training to use new systems. This continuous learning is crucial for staying competitive in the 
                    market.`
                }, {
                    title: 'Performance Management',
                    para: `Tracking employee performance in retail can be complex. Retail managers need effective systems to assess productivity and provide 
                    feedback.`
                },
            ]
        },
        "benefit": {
            heading: 'Benefits of Working with Us',
            content: [
                {
                    title: 'Expert Guidance',
                    para: `Get professional help for your HR needs, ensuring efficiency and effectiveness.`
                }, {
                    title: 'Customized Services',
                    para: `We personalize our solutions to fit the unique needs of your retail business.`
                },
                {
                    title: 'Cost-Effective Strategies',
                    para: `Reduce costs associated with hiring and training with our efficient HR methods.`
                }, {
                    title: 'Advanced Employee Skills',
                    para: `Enhance team performance with focused training programs designed for retail.`
                }, {
                    title: 'Increased Customer Satisfaction',
                    para: `Improve overall service quality, leading to happier customers and repeat business.`
                },
            ]
        },
        "solutions": {
            heading: 'Our Solutions',
            content: [
                {
                    title: 'Tailored Recruitment',
                    para: `We help find the right candidates for your retail business. Our recruitment strategies are designed to align perfectly with your unique 
                    needs.`
                }, {
                    title: 'Training Programs',
                    para: `Our training programs focus on customer service skills. We ensure your staff is knowledgeable and confident in their 
                    roles.`
                },
                {
                    title: 'Retention Initiatives',
                    para: `We develop strategies to improve employee satisfaction. Employees who feel valued are more likely to stick around and add value to your 
                    business.`
                }, {
                    title: 'Performance Tracking Systems',
                    para: `We implement easy systems to monitor employee performance. This helps you identify areas for improvement and recognize strong 
                    performers.`
                }, {
                    title: 'Technology Training',
                    para: `Our training sessions cover the latest retail technologies. We keep your staff updated on tools that enhance customer 
                    experience.`
                },
            ]
        },
        conclusion: `Struggling with HR issues in retail? Partner with Merida HR Consulting and get help from the experts who can help your business grow and 
        succeed!`,
        "img1": "../Assest/PermanentStaffing1.jpeg",
        "img2": "../Assest/PermanentStaffing2.jpeg",
        "img3": "../Assest/PermanentStaffing3.jpeg"
    },
    {
        "id": 8,
        "SID": "manufacturing",
        "title": "Manufacturing",
        "icon": "../Assest/salary.png",
        bannerHeading:'One-Stop Solutions for Manufacturing HR Needs',
        bannertext:'From recruitment to training, we offer integrated HR services designed to meet all your manufacturing needs.',
        "heading1": "Welcome to  Merida HR Consulting – Your Reliable Partner in Seamless Payroll Processing",
        "overview": {
            heading: 'Industry Overview',
            content: [
                {
                    title: '',
                    para: `Manufacturing is essential to our economy, producing everything from everyday essentials to advanced technology. This industry requires skilled workers and efficient processes to ensure that products are made on time and meet quality standards. As factories and production lines grow, having a strong workforce management system becomes 
                    essential.`
                }, {
                    title: '',
                    para: `Merida HR Consulting understands the unique needs of the manufacturing sector. We are dedicated to helping businesses manage their human resources effectively, focusing on areas like production, supply chain management, and logistics. Our HR services are designed to streamline operations, enhance worker safety, and improve overall performance. By partnering with us, you can ensure that your manufacturing business runs smoothly and efficiently, leading to higher productivity and 
                    profitability.`
                },
            ]
        },
        "challenges": {
            heading: 'Common HR Challenges',
            content: [
                {
                    title: 'Talent Shortage',
                    para: `Finding qualified workers can be difficult. Many manufacturing companies struggle to hire candidates with the necessary skills and 
                    experience.`
                }, {
                    title: 'High Turnover',
                    para: `Employee turnover can disrupt production. Frequent changes in staff can lead to loss of knowledge and decreased 
                    efficiency.`
                },
                {
                    title: 'Safety Concerns',
                    para: `Safety is critical in manufacturing. Companies face challenges in training employees to follow safety protocols and reduce workplace 
                    accidents.`
                }, {
                    title: 'Skills Gap',
                    para: `New technologies in manufacturing require up-to-date skills. Many workers need additional training to keep pace with these 
                    changes.`
                }, {
                    title: 'Performance Evaluation',
                    para: `Measuring employee performance can be tricky. Companies often lack clear systems to assess and improve workforce 
                    productivity.`
                },
            ]
        },
        "benefit": {
            heading: 'Benefits of Working with Us',
            content: [
                {
                    title: 'Expert Guidance',
                    para: `Get professional support in managing your HR needs effectively.`
                }, {
                    title: 'Customized Services',
                    para: `We tailor our solutions to fit your business’s unique needs.`
                },
                {
                    title: 'Cost Saving',
                    para: `Reduce hiring and training costs with our efficient HR strategies.`
                }, {
                    title: 'Improved Safety',
                    para: `Enhance workplace safety through our comprehensive training programs.`
                }, {
                    title: 'Better Performance',
                    para: `Enhance overall performance with our targeted skill development initiatives.`
                },
            ]
        },
        "solutions": {
            heading: 'Our Solutions',
            content: [
                {
                    title: 'Targeted Recruitment',
                    para: `We help you find the right talent quickly. Our recruitment strategies are tailored to meet your specific needs in 
                    manufacturing.`
                }, {
                    title: 'Retention Programs',
                    para: `We develop strategies to keep your employees happy and engaged. When employees are satisfied, they're more likely to stick around and give their best 
                    effort.`
                },
                {
                    title: 'Safety Training',
                    para: `We provide essential safety training for all employees. Our programs ensure that workers understand safety rules and 
                    practices.`
                }, {
                    title: 'Skills Development',
                    para: `We offer training programs to help workers learn new skills. Our focus is on keeping your team up-to-date with industry 
                    standards.`
                }, {
                    title: 'Performance Management',
                    para: `We create effective performance evaluation systems. Our approach helps you assess and enhance employee performance 
                    easily.`
                },
            ]
        },
        conclusion: `Don’t let HR challenges slow down your manufacturing business. Team up with Merida HR Consulting for HR solutions that are perfectly tailored to your needs. Let us help you find the right talent and improve your team's 
        performance today!`,
        "img1": "../Assest/PermanentStaffing1.jpeg",
        "img2": "../Assest/PermanentStaffing2.jpeg",
        "img3": "../Assest/PermanentStaffing3.jpeg"
    }

]


export { serviceContent, industrycontent }