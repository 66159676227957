import React from 'react'
import MessageIcon from '../../SVG/MessageIcon'
import TagIcon from '../../SVG/TagIcon'
import { useNavigate } from 'react-router-dom';

const ServiceBlogCard = ({ data }) => {
    function formatDate(dateString) {
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

        const date = new Date(dateString);
        const day = date.getDate();
        const month = months[date.getMonth()];

        return `${day} ${month}`;
    }
    let navigate = useNavigate()
    return (
        <div>
            {/* card */}
            <div onClick={() => navigate(`/blogs/${data && data.slug}`)} 
            className='rounded-xl mb-3 border-1 cursor-pointer border-slate-200 
                     h-fit p-2 flex gap-2 ' >
                <div className='w-[40%]  relative' >
                    <img src={data && data.img ? data.img :
                        require('../../Assest/DashboardLight.webp')} alt="DashBoard"
                        className='block h-full object-cover rounded-xl ' />
                    <div className='bgindigo absolute rounded top-2 left-2
                            text-white text-center text-xs p-[2px] w-9 h-9 ' >
                        {data && data.created_at &&
                            formatDate(data.created_at)}

                    </div>
                </div>
                <article className='p-3 flex-1 ' >
                    <div className='poppins text-sm flex gap-2 ' >
                        <p className='flex gap-1 items-center mb-0 ' > <span className='text-violet-900 ' >
                            <TagIcon size={14} /> </span>
                            {data && data.Blog_Category &&
                                data.Blog_Category.name} </p>
                        <p className='flex gap-1 items-center mb-0 '> <span className='text-violet-900 ' >
                            <MessageIcon size={14} /> </span>
                            Technology </p>
                    </div>
                    <p className=' line-clamp-2 text-xl fw-semibold ' >
                        {data && data.Main_Title}  </p>
                </article>
            </div>
        </div>
    )
}

export default ServiceBlogCard