import React, { useState } from 'react'
import TruckIcon from '../../SVG/TruckIcon'
import HRMCard from '../Cards/HRMCard'
import MountainIcon from '../../SVG/MountainIcon'
import CampIcon from '../../SVG/CampIcon'
import TentIcon from '../../SVG/TentIcon'
import AdventureIcon from '../../SVG/AdventureIcon'
import CalenderIcon from '../../SVG/CalenderIcon'
import CalenderIcon2 from '../../SVG/CalenderIcon2'
const WhyHrmsNeed = () => {
    let [hoverIndex, setIndex] = useState()
    let cards = [
        {
            size: 120,
            heading: "Employee Tracking",
            icon: TruckIcon,
            para: `Easily monitor employee performance and attendance to ensure everyone stays engaged and works together towards achieving the company’s
             goals.`,
        },
        {
            size: 110,

            heading: "Recruitment",
            icon: CampIcon,
            para: `Make the hiring process simple with tools that help you find, interview, and welcome the right candidates to join your team.`,
        },
        {
            size: 110,

            heading: "Attendance",
            icon: MountainIcon,
            para: `Automate attendance tracking to keep accurate records, cut down on time theft, and encourage everyone to be accountable at 
            work`,
        },
        {
            size: 120,

            heading: "Payroll",
            icon: TentIcon,
            para: `Generate accurate pay slips and process payroll on time, ensuring employees feel valued and confident in their 
            compensation.`,
        },
        {
            size: 120,

            heading: "Announcements ",
            icon: AdventureIcon,
            para: `Keep all employees updated on important company news and announcements, helping everyone stay connected to your organization’s goals and 
            mission.`,
        },
        {
            size: 110,

            heading: "Employee Happiness",
            icon: CalenderIcon2,
            para: `Enhance job satisfaction through regular appraisals and talent development, creating a workplace where employees feel appreciated and motivated to 
            grow. `,
        }

    ]
    return (
        <div className='poppins ' >
            <h4 className='text-center text-3xl sm:text-4xl fw-semibold ' >Why
                <br /> HRMS Is Important  </h4>
            <section className='relative min-h-[60vh]  ' >
                <img src={require('../../Assest/backgroundMoon.png')} alt="background" className='absolute top-0  ' />
                {/* Cards */}
                <article
                    className='flex relative gap-3 py-5 flex-wrap justify-around
                     sm:justify-between container' >
                    {
                        cards.map((obj, index) => (
                            <HRMCard size={obj.size} Child={obj.icon} setIndex={setIndex}
                                index={index} heading={obj.heading} para={obj.para}
                                hoverIndex={hoverIndex} />
                        ))
                    }
                </article>
            </section>

        </div>
    )
}

export default WhyHrmsNeed