import React from 'react'
import LocationIcon from '../SVG/LocationIcon';
import { useNavigate } from 'react-router-dom';

const RightAvailableJobs = ({ data }) => {
    console.log(data);
    let navigate = useNavigate()
    return (
        <div className='my-3 ' >
            {
                data && [...data].reverse().map((obj, index) => (
                    <div onClick={() => navigate(`/jobs/${obj.slug}`)}
                        className='cursor-pointer duration-300 my-2 text-sm' >
                        <p className=' fw-semibold mb-1 ' >{obj.Title} </p>
                        < p className=' my-3 mt-1 ' >{obj.company_inrto} </p>
                        <section className='flex items-center justify-between flex-wrap gap-3 ' >
                            <div className='flex gap-1 items-center ' >
                                <LocationIcon />
                                {obj.job_location}
                            </div>
                            <p className='mb-0 text-xs  ' >Posted : {obj.posted_on} </p>
                        </section>
                        <hr className='my-2 ' />

                    </div>
                ))
            }
        </div>
    )
}

export default RightAvailableJobs