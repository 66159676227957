import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './index.css';
import HomePage from './Pages/HomePage';
import CareersPage from './Pages/CareersPage';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Css/careers.css'
import './Css/home.css'
import './Css/hrsection.css'
import './Css/service.css'
import Footer from './Component/Footer';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ContactPage from './Pages/ContactPage';
import HrmsPublish from './Pages/HrmsPublish';
import ServicePage from './Pages/ServicePage';
import SectorsPage from './Pages/SectorsPage';
import ParticularCareerPage from './Pages/ParticularCareerPage';
import AllBlogPage from './Pages/AllBlogPage';
import ParticularBlog from './Pages/ParticularBlog';
import 'react-toastify/dist/ReactToastify.css';
import EventPage from './Pages/Events/EventsPage';
import ParticularEvent from './Pages/Events/ParticularEventPage';



export const port = 'https://backendapi.meridahr.com'
export const slaport = 'https://backendapi.skilllearningacademy.com'
// export const port = 'http://192.168.218.249:7000'
// export const hrport='http://192.168.218.237:3000'
export const hrport = 'https://hrm.meridahr.com'
export const domain = 'https://www.meridahr.com'



function App() {

  return (
    <div className="">
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<HomePage />} />
          <Route path='/careers/*' element={<CareersPage />} />
          <Route path='/jobs/:id' element={<ParticularCareerPage />} />

          <Route path='/contact' element={<ContactPage />} />
          <Route path='/about' element={<HrmsPublish />} />
          <Route path='/service/*' element={<ServicePage />} />
          <Route path='/service/:sid' element={<ServicePage />} />
          <Route path='/industries/:id' element={<SectorsPage />} />
          <Route path='/blogs/:slug' element={<ParticularBlog />} />
          <Route path='/blogs/*' element={<AllBlogPage />} />

          <Route path='/events/*' element={<EventPage />} />
          <Route path='/events/:slug' element={<ParticularEvent />} />


        </Routes>
        <Footer />

      </BrowserRouter>

    </div>
  );
}

export default App;
