import React from 'react'

const HomeContact = () => {
    return (
        <div className='bgevent' >
            <main className='row pt-5  pe-0 container-fluid ' >
                <section className='col-lg-4 flex ' >
                    <img src={require('../../Assest/building.webp')} className='mt-auto ' alt="Building" />
                </section>
                <section className='col-lg-8 px-5   ' >
                    <div className='rounded shadow bg-slate-50 mb-5 bg-opacity-50 p-4 border-2 w-full min-h-[10vh] backdrop-blur-sm ' >
                        <h4 className='text-center txtgrd text-3xl fw-semibold ' >Get in Touch </h4>
                        <article className='flex gap-3 ' >
                            <div className='w-5/6 ' >
                                <p className='fw-semibold ' >Full Name</p>
                                <input type="text" placeholder='Full name'
                                    className=' w-full rounded outline-none border-2 border-slate-300 block border-opacity-70 p-2 ' />
                            </div>
                            <div className='w-5/6 ' >
                                <p className='fw-semibold ' >Email</p>
                                <input type="text" placeholder='Full name'
                                    className=' w-full rounded outline-none border-2 border-slate-300 block border-opacity-70 p-2 ' />
                            </div>
                        </article>
                        <div className=' my-3' >
                            <p className='fw-semibold ' >Subject</p>
                            <input type="text" placeholder='Full name'
                                className=' w-full rounded outline-none border-2 border-slate-300 block border-opacity-70 p-2 ' />
                        </div>
                        <div className='' >
                            <p className='fw-semibold ' >How can we help you?</p>
                            <textarea rows={5} type="text" placeholder='Describe your problem in at least 250 characters'
                                className=' w-full rounded outline-none border-2 border-slate-300 block border-opacity-70 p-2 ' />
                        </div>
                        <button className='p-2 px-3 mx-auto rounded flex mt-3 text-sm bg-blue-500 text-white ' >
                            Send Message
                        </button>
                    </div>

                </section>

            </main>

        </div>
    )
}

export default HomeContact