import React, { useContext, useEffect } from 'react'
import Slider from 'react-slick'
import HomeBlogCard from '../Cards/HomeBlogCard'
import { HrStorage } from '../../Context/HrStore'

const OurBlogs = () => {
    let { allblog, getallblog } = useContext(HrStorage)
    let settings = {
        dots: false,
        arrows: false,
        speed: 2000,
        slidesToShow: 3,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToScroll: 1,
        initialSlide: 0,
        infinite: true,
        responsive: [
            {
                breakpoint: 1040,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 830,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }
    useEffect(() => {
        getallblog()
    }, [])

    return (
        <div className='xl:min-h-[40vh] py-5' >
            <main className='container mx-auto ' >
                <p className='text-blue-800 text-center uppercase mb-1 fw-semibold  ' >Case Studies </p>
                <img src={require('../../Assest/blogicons.png')} alt="Blog Icon" className='w-10 mx-auto flex ' />
                <p className='text-slate-900 text-2xl text-center uppercase fw-bold  ' >Our Blogs </p>
                {/* Cards */}

                <Slider {...settings} className='py-2 ' >
                    {
                        allblog && [...allblog].reverse().slice(0, 4).map((obj, index) => (
                            <HomeBlogCard data={obj} /> 
                        ))
                    }
                </Slider>
            </main>

        </div>
    )
}

export default OurBlogs