import React, { useEffect, useState } from 'react'
import { ReCAPTCHA } from 'react-google-recaptcha'
import { port } from '../App'
import axios from 'axios'
import { toast } from 'react-toastify'

const ContactForm = () => {
    let [errorMessage, setErrormessage] = useState('')
    const [contactForm, setContactForm] = useState({
        email: '',
        message: '',
        first_name: '',
        last_name: '',
        phone: '',
        captcha: false
    })
    let [loading, setloading] = useState('')
    let handlechange = (e) => {
        let { name, value } = e.target
        if (name == 'phone' && value.length > 10)
            value = contactForm.phone
        setContactForm((prev) => ({
            ...prev,
            [name]: value
        }))
    }
    let validateForm = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (emailRegex.test(contactForm.email) && contactForm.phone != '' && contactForm.first_name != '' && contactForm.message != '') {
            setErrormessage('')
            return true
        }
        else if (!emailRegex.test(contactForm.email) && contactForm.phone == '' && contactForm.first_name == '' && contactForm.message == '') {
            setErrormessage('*Enter all the required inputs')
        }
        else if (!emailRegex.test(contactForm.email)) {
            setErrormessage("Enter the proper mail and other inputs")
        }
        else {
            setErrormessage('*Enter all the required inputs')
        }
    }
    let postContact = () => {
        if (contactForm.captcha) {
            if (validateForm()) {
                setloading('contact')
                axios.post(`${port}/blog/ContactsInformation/`, contactForm).then((response) => {
                    console.log(response.data);
                    toast.success('Your Message has been sended, Our team will get touch in short time.')
                    setloading('')
                    setContactForm({
                        email: '',
                        message: '',
                        first_name: '',
                        last_name: '',
                        phone: ''
                    })
                }).catch((error) => {
                    console.log(error);
                    setloading('')
                })
            }
        }
        else {
            setErrormessage('*Check the Captcha')

        }
    }
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className='py-5' >
            <main className='container bg-white  min-h-[30vh] mx-auto px-0 overflow-hidden row rounded-xl ' >
                <section className=' col-sm-6  p-5 px-sm-5 poppins ' >
                    <h4 className='text-blue-600 fw-semibold ' >Get in touch </h4>
                    <p className=' ' >We are here for you! How can we help? </p>
                    <div>
                        Name
                        <input type="text" name='first_name' onChange={handlechange} value={contactForm.first_name}
                            className='my-2 rounded w-full border-2 border-blue-600 outline-none p-1 ' />
                    </div>
                    <div>
                        Email
                        <input type="text" name='email' onChange={handlechange} value={contactForm.email}
                            className='my-2 rounded w-full border-2 border-blue-600 outline-none p-1 ' />
                    </div>
                    <div>
                        Message
                        <textarea rows={5} name='message' onChange={handlechange} value={contactForm.message}
                            type="text" className='my-2 rounded w-full border-2 border-blue-600 outline-none p-1 ' />
                    </div>

                    <p className='h-[15px] text-red-600 text-center text-sm ' > {errorMessage} </p>
                    <div className='p-2 flex items-center justify-center'>
                        <ReCAPTCHA
                            className=' md:scale-[0.90] inputbg '
                            spellCheck={contactForm.humanverification}
                            sitekey="6LeYRF0qAAAAACTDwiTBcsyIbtbZCl59hcFHWCdI"
                            onChange={() => {
                                setContactForm((prev) => ({
                                    ...prev,
                                    humanverification: !contactForm.humanverification
                                }))
                            }}
                        />
                    </div>
                    <button onClick={postContact} className='w-full shadow rounded p-2 bg-blue-800 text-white shadow-pink-500 ' >
                        Submit
                    </button>

                </section>
                <section className='col-sm-6 contactrightbg flex ' >
                    <div className=' w-7/12 m-auto h-2/3 relative border-1 rounded-xl
                     backdrop-blur-sm bg-opacity-30 bg-slate-50 p-3 text-white ' >
                        <h4 className='w-2/3 ' >Why are waiting for you Contact Now!!!  </h4>
                        <img src={require('../Assest/women.webp')} alt="Women" className='w-60  bottom-0 absolute -right-12 ' />
                        <div className='bg-slate-50 w-12 rounded-full p-2 shadow absolute top-1/2 -left-7 ' >
                            <img src={require('../Assest/bolt.webp')} alt="Bolt" className='' />
                        </div>
                    </div>

                </section>

            </main>

        </div>
    )
}

export default ContactForm