import React, { useState } from 'react'
import SearchIcon from '../../SVG/SearchIcon'
import HomeNavBar from '../HomeComponent/HomeNavBar'

const SearchBarSection = ({ allJob, setFilteredData }) => {
    let [searchWord, setSearchWord] = useState()
    let searchFilter = () => {
        console.log(allJob);
        let filteredData = allJob.filter((obj, index) => obj.Title &&
            obj.Title.toLowerCase().indexOf(searchWord.toLowerCase()) != -1)
        console.log(filteredData);
        setFilteredData(filteredData)
    }
    return (
        <div className='w-full searchbar p-5 px-0  pt-0 min-h-[30vh] poppins' >
            <HomeNavBar type='white' />
            <main className='px-3 my-3 '  >

                <h4 className='text-white text-xl sm:text-3xl xl:text-4xl fw-semibold    '>Find your
                    <span className='text-violet-800 ' > new job</span> today</h4>
                <p className='my-3 text-sm ' >Hundreds of jobs waiting for you. </p>
                <section className='flex w-full rounded overflow-hidden ' >
                    <div className=' p-2 flex-1 flex gap-2 bg-white   text-sm ' >
                        <SearchIcon />
                        <input value={searchWord} onKeyDown={(e) => {
                            if (e.key == 'Enter') {
                                searchFilter()
                            }
                        }}
                            onChange={(e) => setSearchWord(e.target.value)}
                            type="text" className='w-full outline-none '
                            placeholder='What position are you looking for ?' />
                    </div>
                    <button onClick={searchFilter} className='bg-violet-800 text-white text-sm p-2 px-3 ' >
                        Search job
                    </button>
                </section>

            </main>

        </div>
    )
}

export default SearchBarSection