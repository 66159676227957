import React from 'react'
import HomeNavBar from '../HomeComponent/HomeNavBar'

const SectorBanner = ({ data }) => {
    return (
        <div>
            <main className='xl:min-h-[50vh] relative flex flex-col ' >
                <img src={require('../../Assest/serviceBanner.webp')} alt="Service" />
                <div className='absolute top-0 w-full  ' >

                    <HomeNavBar type="white" />
                    <h5 className='text-3xl sm:text-5xl lg:text-8xl w-10/12 mx-auto text-slate-50 text-center my-3 syne  ' >
                        {data.bannerHeading ? data.bannerHeading : "Redefining Digital Experiences"} </h5>
                    <p className='text-slate-200 text-center ' >
                        {data.bannertext ? data.bannertext : `Transform your brand, unleash the power of digital
                        with us and turn bold visions into market success `}</p>
                    {/* <section className='w-fit flex mx-auto my-5  syne   ' >
                        <p className='w-44 border-e-2 border-slate-100 text-center text-sm uppercase text-slate-50 ' > Web  </p>
                        <p className='w-44 border-e-2 border-slate-100 text-center text-sm uppercase text-slate-50 ' > App  </p>
                        <p className='w-44 border-e-2 border-slate-100 text-center text-sm uppercase text-slate-50 ' > Print  </p>
                        <p className='w-44 border-slate-100 text-center text-sm uppercase text-slate-50 syne' > Social  </p>

                    </section> */}

                </div>
            </main>
        </div>
    )
}

export default SectorBanner