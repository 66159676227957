import React from 'react'

const BellIcon = ({ size }) => {
    return (
        <svg width={size ? size : "31"} height={size ? size : "31"} viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M26.5443 19.502C25.5555 17.8012 25.0304 15.3599 25.0304 12.447C25.0304 9.90915 24.0222 7.47521 22.2276 5.68066C20.4331 3.8861 17.9991 2.87793 15.4612 2.87793C12.9234 2.87793 10.4894 3.8861 8.69487 5.68066C6.90031 7.47521 5.89214 9.90915 5.89214 12.447C5.89214 15.3611 5.36926 17.8012 4.38045 19.502C4.17868 19.8489 4.07173 20.2428 4.07033 20.6441C4.06893 21.0454 4.17313 21.44 4.37248 21.7883C4.57056 22.1368 4.85807 22.4261 5.20532 22.6264C5.55256 22.8266 5.94697 22.9306 6.34781 22.9275H10.4694C10.5824 24.1733 11.1572 25.3319 12.0809 26.1755C13.0045 27.0192 14.2103 27.487 15.4612 27.487C16.7122 27.487 17.918 27.0192 18.8416 26.1755C19.7653 25.3319 20.3401 24.1733 20.4531 22.9275H24.5747C24.9749 22.93 25.3686 22.8257 25.7152 22.6255C26.0618 22.4253 26.3488 22.1363 26.5466 21.7883C26.7468 21.4405 26.852 21.0461 26.8516 20.6448C26.8512 20.2435 26.7452 19.8494 26.5443 19.502ZM15.4612 24.7502C14.936 24.7503 14.4269 24.5689 14.02 24.2368C13.6131 23.9046 13.3335 23.4421 13.2285 22.9275H17.694C17.589 23.4421 17.3094 23.9046 16.9025 24.2368C16.4956 24.5689 15.9865 24.7503 15.4612 24.7502ZM7.10651 20.1935C8.11468 18.1429 8.62617 15.5388 8.62617 12.447C8.62617 10.6343 9.34629 8.89573 10.6281 7.61391C11.9099 6.33208 13.6485 5.61196 15.4612 5.61196C17.274 5.61196 19.0126 6.33208 20.2944 7.61391C21.5762 8.89573 22.2963 10.6343 22.2963 12.447C22.2963 15.5376 22.8067 18.1429 23.8149 20.1935H7.10651Z"
                fill="currentColor" />
        </svg>

    )
}

export default BellIcon