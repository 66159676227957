import React from 'react'

const LeafIcon = ({ size }) => {
    return (
        <svg width={size ? size : "24"} height={size ? size : "24"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23.0859 2.14039C23.0664 1.80596 22.9248 1.49034 22.6879 1.25346C22.451 1.01658 22.1354 0.874929 21.8009 0.855399C17.2898 0.58997 13.3038 1.12197 9.95348 2.43544C6.74782 3.69196 4.27694 5.63995 2.80512 8.06868C0.79789 11.3848 0.793333 15.4107 2.75386 19.2543L0.768271 21.2399C0.641112 21.367 0.540244 21.518 0.471426 21.6841C0.402608 21.8503 0.367188 22.0283 0.367188 22.2082C0.367187 22.388 0.402608 22.5661 0.471426 22.7322C0.540244 22.8984 0.641112 23.0493 0.768271 23.1765C1.02508 23.4333 1.37339 23.5776 1.73657 23.5776C1.9164 23.5776 2.09447 23.5421 2.26062 23.4733C2.42676 23.4045 2.57772 23.3036 2.70488 23.1765L4.69047 21.1909C6.59745 22.1638 8.55001 22.6547 10.4376 22.6547C12.3555 22.6607 14.2376 22.1364 15.8761 21.1396C18.3048 19.6678 20.2528 17.1958 21.5093 13.9913C22.8194 10.6387 23.3514 6.65154 23.0859 2.14039ZM14.4555 18.7975C12.1669 20.1839 9.48185 20.2887 6.74099 19.1301L16.3716 9.49949C16.4988 9.37233 16.5996 9.22137 16.6685 9.05523C16.7373 8.88909 16.7727 8.71102 16.7727 8.53119C16.7727 8.35136 16.7373 8.17329 16.6685 8.00715C16.5996 7.84101 16.4988 7.69005 16.3716 7.56289C16.2445 7.43573 16.0935 7.33486 15.9274 7.26604C15.7612 7.19722 15.5831 7.1618 15.4033 7.1618C15.2235 7.1618 15.0454 7.19722 14.8793 7.26604C14.7131 7.33486 14.5622 7.43573 14.435 7.56289L4.81122 17.2003C3.65609 14.4663 3.7609 11.7744 5.15297 9.48582C7.535 5.55223 13.1887 3.36615 20.418 3.53361C20.5752 10.7606 18.3891 16.4155 14.4555 18.7975Z"
                fill="currentColor" />
        </svg>

    )
}

export default LeafIcon