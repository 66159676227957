import React from 'react'
import LocationIcon from '../../SVG/LocationIcon'
import ClockIcon from '../../SVG/ClockIcon'
import CurrencyIcon from '../../SVG/CurrencyIcon'
import CalenderIcon from '../../SVG/CalenderIcon'

const JobCard = ({ data }) => {
    return (
        <div>
            <main className='flex gap-3 my-2 p-4 bg-white shadow-sm rounded ' >
                <img src={require('../../Assest/jobIcon.png')} alt="Icon" className='w-16 h-fit ' loading='lazy' />
                <section>
                    <p className='mb-1' > {data.company_inrto} </p>
                    <h4> {data.Title} </h4>
                    <article className='flex gap-3 flex-wrap text-xs items-center ' >
                        <div className='flex items-center gap-1  ' >
                            <LocationIcon size={12} />
                            {data.job_location}
                        </div>
                        <div className='flex items-center gap-1  ' >
                            <ClockIcon size={12} />
                            {data.job_type}
                        </div>
                        <div className='flex items-center gap-1  ' >
                            <CurrencyIcon size={15} />
                            {data.min_salary && (data.min_salary) + data.salary_type}
                            {data.min_salary && data.max_salary && " - "}
                            {data.max_salary && (data.max_salary) + data.salary_type}

                        </div>
                        <div className='flex items-center gap-1  ' >
                            <CalenderIcon size={12} />
                            {data.min_exp && (data.min_exp) + "yrs"}
                            {data.min_exp && data.Experience && " - "}
                            {data.Experience && (data.Experience) + "yrs "}
                        </div>
                    </article>
                    <p className='my-2 text-sm line-clamp-2' >
                        {data.Job_Discription} </p>
                </section>
            </main>

        </div>
    )
}

export default JobCard