import React from 'react'
import LineIcon from '../../SVG/LineIcon'

const SectorContact = (
    { data }
) => {
    return (
        <div className={` container z-10 bg-green-300 relative  `} >
            <main className={`bg-yellow-300 z-5 p-3 p-sm-5 poppins
     min-h-[42vh] rounded-t-[40px] rounded-b-[40px]  row `} >
                <section className='col-md-6 flex' >
                    <img src={require('../../Assest/sectorContact.webp')} className='w-[20rem] m-auto ' alt="Correction" />
                </section>
                <section className='col-md-6 poppins ' >
                    <div className='flex items-center gap-2' >
                        <LineIcon />
                        What we do
                    </div>
                    <p className=' fw-normal my-3 ' > {data.conclusion ? data.conclusion : "We Think, Create, and Make Designs that Good and Inpire."} </p>
                    <button className='rounded border-1 p-2 px-4 border-slate-950 ' >
                        Check Our Work
                    </button>
                </section>
            </main>

        </div>
    )
}

export default SectorContact